import { Box, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonalChannel from "../PersonalChannel";
import { useLocation, useSearchParams } from "react-router-dom";
import PageRoutes, { INSIDER_PLAN_ID } from "../../utils/constants";
import MyNAHLTV from "../MyNAHLTV";
import MyClips from "./MyClips";
import DownloadClipsList from "./DownloadClipsList";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DownloadTabs = ({ children, subscriptionDetails }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tab_id: string | null = searchParams?.get("tab_id");

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    searchParams.set("tab_id", newValue.toString());
    setSearchParams(searchParams);
  };
  const userDetails = useCommonDetails();
  const location = useLocation();
  useEffect(() => {
    if (tab_id && parseInt(tab_id) < 3) {
      setValue(parseInt(tab_id));
    } else {
      setValue(0);
    }
  }, [tab_id]);
  return (
    <Box>
      <Box sx={{ width: "100%", p: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{
                "&.MuiTab-root": {
                  display:
                    process.env.REACT_APP_FEATURE !== "true" ? "none" : "flex",
                },
              }}
              label="Channels"
              {...a11yProps(0)}
            />

            <Tab label="Clips" {...a11yProps(1)} />

            <Tab label="Downloads" {...a11yProps(2)} />
          </Tabs>
        </Box>
        {process.env.REACT_APP_FEATURE == "true" && (
          <CustomTabPanel value={value} index={0}>
            {location.pathname.includes("my-channel") ? (
              <MyNAHLTV />
            ) : (
              <PersonalChannel subscriptionDetails={subscriptionDetails} />
            )}
          </CustomTabPanel>
        )}

        <CustomTabPanel value={value} index={1}>
          <MyClips
            isInsider={
              subscriptionDetails?.plan_type_id == INSIDER_PLAN_ID ||
              userDetails?.UserRole?.Permissions?.includes("download")
            }
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <DownloadClipsList
            isInsider={
              subscriptionDetails?.plan_type_id == INSIDER_PLAN_ID ||
              userDetails?.UserRole?.Permissions?.includes("download")
            }
          />
        </CustomTabPanel>
      </Box>
      {children}
    </Box>
  );
};
export default DownloadTabs;
