import {
  Button,
  Dialog,
  Grid,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import useConfig from "../../../../hooks/useConfig";

import { toast } from "react-toastify";
import HttpService from "../../../../hooks/Https-services";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import SliderForm from "./SliderForm";

const CreateSliderForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState("");
  console.log(fileUrl);
  const configData = useConfig();
  const navigate = useNavigate();
  const [initalValue, setIntialValue] = useState({
    name: "",
    description: "",
    displayText: "",
    mobileDisplayText: "",
    fontSize: "",
    fontColor: "#ffffff",
    priority: "",
    seasonId: "",
    gameId: null,
    images: null as any,
    type: "channel",
  });
  const { id }: any = useParams();
  const getSliderDetails = async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetHomeSlider.replace(":id", id)
      );

      const data = res?.data?.data;
      setIntialValue({
        ...initalValue,
        name: data?.Name == undefined ? "" : data?.Name,
        displayText: data?.DisplayText,
        description: data?.Description,
        fontColor: data?.FontColor,
        mobileDisplayText: data?.MobileText,
        fontSize: data?.FontSize,
        priority: data?.Priority,
        seasonId: JSON.parse(data?.WatchUrl),
        gameId: data?.Type === "game" ? JSON.parse(data?.WatchUrl) : null,
        images: null as any,
        type: data?.Type || "channel",
      });
      setFileUrl(data?.Image);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id != 0) {
      getSliderDetails();
    } else {
      setIsLoading(false);
    }
  }, []);
  return (
    <Box>
      <Box>
        {!isLoading && (
          <SliderForm
            initalValue={initalValue}
            setFileUrl={setFileUrl}
            fileUrl={fileUrl}
          />
        )}
      </Box>
    </Box>
  );
};

export default CreateSliderForm;
