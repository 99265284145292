import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";
import React, { useContext, useEffect, useState } from "react";
import useConfig from "../../../hooks/useConfig";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChannelCards from "./ChannelCards";
import { useNavigate, useParams } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import axios from "../../../utils/axios";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { UserContext } from "..";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import commonDetailSlice from "../../../store/reducers/commonReducer";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import EllipsisContent from "../../../Common/Component/EllipsisContent";
import HttpService from "../../../hooks/Https-services";

const ChannelPreferencess = (props: any) => {
  const navigate = useNavigate();
  const { handleNext, handleBack } = props;
  // const actions = ['Goal','Shot Attempt','Save','Off The Boards','Face off','Penalty','Hit Taken','Check Stick']
  const configData = useConfig();
  const {
    selectedEvent,
    setSelectedEvent,
    selectedPlayer,
    channel,
    loading,
    setLoading,
    isSelectAllPlayer,
    setIsSelectAllPlayers,
    isSelectAllEvent,
    setIsSelectAllEvents,
    selectAllTeamPlayers,
    setSelectAllTeamPlayers,
  }: any = useContext(UserContext);
  const [all, setAll] = useState(false);
  const [select, setSelect] = useState(false);
  const [actions, setActions] = useState([]);
  const [isSkeleton, setIsSkeleton] = useState(true);

  const userDetails = useCommonDetails();
  const dispatch = useDispatch();
  async function getEntries(data: any) {
    let submitdata = { ...data };
    try {
      const res = await HttpService.get(APIRoutes.GetAllChannel);
      if (res.data.data == null || res?.data?.data?.lenghth == 0) {
        submitdata.isChannelCreated = false;
      } else {
        submitdata.isChannelCreated = true;
      }
      dispatch(commonDetailSlice.actions.removecommonDetails());
      dispatch(commonDetailSlice.actions.setcommonDetails(submitdata));
    } catch (error) {}
  }
  const { channel_id }: any = useParams();
  const createChannel = async () => {
    const updateConfigure = {
      Name: channel?.Configuration?.Name,
      Teams: channel?.Configuration?.Teams,
      Players: selectedPlayer,
      Events: selectedEvent,
      All: selectAllTeamPlayers,
    };
    setLoading(true);

    try {
      const res = await HttpService.post(
        APIRoutes.UpdateChannel.replace(":id", channel_id),
        {
          configure: updateConfigure,
        }
      );
      await getEntries(userDetails);
      navigate(PageRoutes.PersonalChannel.replace(":id", channel_id));

      handleNext();

      setLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };
  const GetEvents = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetEvents);
      if (res) {
        setActions(res.data.data);
        setIsSkeleton(false);
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    GetEvents();
  }, []);

  return (
    <Box mt={8}>
      <Container component="main" maxWidth="md" sx={{ mt: 2 }}>
        <Box>
          <Accordion sx={{ my: 2 }} expanded={true}>
            <AccordionSummary
              sx={{
                borderRadius: "4px",
                boxShadow: "0 .5rem 1rem rgba(0,0,0,0.15)",
                background: `linear-gradient(110deg, ${configData.secondary} 50%, ${configData.primary} 50%)`,
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" color="white">
                Select Your Players{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ChannelCards />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ my: 2 }} expanded={true}>
            <AccordionSummary
              sx={{
                borderRadius: "4px",
                boxShadow: "0 .5rem 1rem rgba(0,0,0,0.15)",
                background: `linear-gradient(110deg, ${configData.secondary} 50%, ${configData.primary} 50%)`,
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6" color="white">
                Select Your Action{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "5px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems:"center",
                  flexWrap: "wrap",
                  // justifyContent: "space-between",
                }}
              >
                {isSkeleton ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      py: 10,
                      width: 1,
                    }}
                  >
                    <CircularProgress
                      size={48}
                      sx={{
                        color: "#bf311a",
                      }}
                    />
                  </Box>
                ) : actions?.length == 0 || !actions ? (
                  <Typography>No data found</Typography>
                ) : (
                  <>
                    {actions.map((ele) => (
                      <Box
                        sx={{
                          width: { xs: "100%", md: "50%" },
                          py: 1,
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid black",
                          overflow: "hidden",
                        }}
                      >
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedEvent([...selectedEvent, ele]);
                            } else {
                              const index = selectedEvent.findIndex(
                                (element: any) => element == ele
                              );
                              if (index != -1) {
                                const teamData = selectedEvent.filter(
                                  (e: any, ind: any) => ind != index
                                );

                                setSelectedEvent(teamData);
                              }
                            }
                          }}
                          checked={selectedEvent?.includes(ele) ? true : false}
                        />
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion sx={{ my: 2 }}>
            <AccordionSummary
              sx={{
                borderRadius: "4px",
                boxShadow: "0 .5rem 1rem rgba(0,0,0,0.15)",
                background: `linear-gradient(110deg, ${configData.secondary} 50%, ${configData.primary} 50%)`,
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography variant="h6" color="white">
                Finalize Channel Settings{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider sx={{ borderColor: "black", my: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  fontWeight="700"
                  sx={{ textTransform: "none", width: "40%" }}
                >
                  Show Events Chronologically
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  By Match
                  <Checkbox />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  By Player
                  <Checkbox />
                </Box>
              </Box>
              <Divider sx={{ borderColor: "black", my: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  fontWeight="700"
                  sx={{ textTransform: "none", width: "40%" }}
                >
                  Add New Matches As Available
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  Yes
                  <Checkbox />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  No
                  <Checkbox />
                </Box>
              </Box>
              <Divider sx={{ borderColor: "black", my: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  fontWeight="700"
                  sx={{ textTransform: "none", width: "40%" }}
                >
                  Notify Me When New Contest is Added
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  Yes
                  <Checkbox />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  No
                  <Checkbox />
                </Box>
              </Box>
              <Divider sx={{ borderColor: "black", my: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  fontWeight="700"
                  sx={{ textTransform: "none", width: "40%" }}
                >
                  Make My Channel Available To Public
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  Yes
                  <Checkbox />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  No
                  <Checkbox />
                </Box>
              </Box>
              <Divider sx={{ borderColor: "black", my: 1 }} />
            </AccordionDetails>
          </Accordion> */}
        </Box>

        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleBack}
              sx={{
                my: { xs: 1, md: 2 },
                textAlign: "center",
                height: "50px",
                background: configData.secondary,
                "&:hover": { background: configData.secondary },
              }}
            >
              <KeyboardArrowLeftIcon /> Back
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={
                selectedPlayer?.length == 0 ||
                (selectedEvent?.length == 0 && true)
              }
              fullWidth
              variant="contained"
              sx={{
                my: { xs: 1, md: 2 },
                textAlign: "center",
                height: "50px",
                background: configData.primary,
                "&:hover": { background: configData.primary },
              }}
              onClick={() => {
                createChannel();
              }}
            >
              BUILD YOUR CHANNEL &nbsp; <QueuePlayNextIcon />
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ChannelPreferencess;
