import React, { useEffect, useState } from "react";
// import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useSearchParams,
} from "react-router-dom";
import Login from "./Pages/Login";
import PageRoutes, { USERROLE, UserRoles } from "./utils/constants";

import PrivateRoutes from "./Common/RouteAccessor/PrivateRoute";
import PublicRoute from "./Common/RouteAccessor/PublicRoute";
import HomePage from "./Pages/HomePage";
import Passes from "./Pages/Passes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import UpcomingEvent from "./Pages/UpcomingEvent";
import OnDemand from "./Pages/OnDemand";
import Register from "./Pages/Register";
import VerifyEmail from "./Pages/EmailVefiy";
import ForgotPassword from "./Pages/ForgotPassword";
import MyAccount from "./Pages/MyAccount";
import EditProfile from "./Pages/MyAccount/EditProfile";
import Profile from "./Pages/MyAccount/Profile";
import TermAndCondition from "./Pages/TermAndCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Watch from "./Pages/Watch";
import Setting from "./Pages/SettingPage";
import Alert from "./Pages/AlertPage";
import NoPageFound from "./Pages/NoPageFound";
import Payment from "./Pages/Payment/Payment";
import ReactGA from "react-ga4";
import ProfileSetting from "./Pages/ProfileSetting";
import PaymentInformation from "./Pages/MyAccount/PaymentInfomation";
import ConfigData from "./Config/config.json";

import { ThemeProvider } from "styled-components";
import WatchGame from "./Pages/Watch/watch";
import { Helmet } from "react-helmet";
import MyNAHLTV from "./Pages/MyNAHLTV";
import PersonalChannel from "./Pages/PersonalChannel";

import PassesMain from "./Pages/Passes/Passes";
import Admin from "./Pages/Admin";
import useCommonDetails from "./Common/Hooks/useCommonDetails";
import AdminRoutes from "./Common/RouteAccessor/AdminRoutes";
import TinyEditor from "./Pages/Admin/AlertDesign/TinyEditor";
import CreateSliderForm from "./Pages/Admin/SliderSettings/CreateSliderForm";
import GiftPage from "./Pages/GiftPage";
import useCheckSubscibed from "./hooks/useCheckSubscibed";
import ChatApp from "./Common/Component/LiveAlertMessage";
import WebSocketComponent from "./Common/Component/LiveAlertMessage";
import MyNahlTv from "./Pages/MyNAHLTV/MyNahlTv";
import PersonalChannelSubscribe from "./Pages/PersonalChannel/PersonalChannelSubscribe";
import UserView from "./Pages/Admin/UsersList/UserView";
import { LoadingContextProvider } from "./Common/Contex/LoadingContex";
import { ThemeProviderReact } from "./Common/Contex/ThemeContex";
import CreatePlayerError from "./Pages/Admin/PlayerAlertList/PlayerAlertListTable/CreatePlayerError";
declare global {
  interface Window {
    dataLayer: any[];
  }
}

function App() {
  const [faviconUrl, setFaviconUrl] = useState<string>("");
  const [manifestData, setMenifest] = useState<string>("");
  const [metaData, setMetaData] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [config, setConfig] = useState<any>({});

  const handleConfig = () => {
    const data: any = ConfigData;
    const websiteName: any = process.env.REACT_APP_ENV;
    setFaviconUrl(data[websiteName]?.favicon);
    setConfig(data[websiteName]);
    setMenifest(`/${process.env.REACT_APP_ENV}manifest.json`);
    setTitle(data[websiteName]?.title);
    const googleAnalyticsId = data[websiteName]?.gTag || "G-KFNJMZE0KF";
    ReactGA.initialize(googleAnalyticsId);
  };

  useEffect(() => {
    // Append the favicon link to the head section when the `faviconUrl` changes.
    const manifest = document.createElement("link");
    const faviconLink = document.createElement("link");
    faviconLink.rel = "icon";
    faviconLink.type = "image/x-icon";

    faviconLink.href = faviconUrl;

    document.head.appendChild(faviconLink);
  }, [faviconUrl]);
  useEffect(() => {
    handleConfig();
    return () => {
      const existingFaviconLink = document.querySelector('link[rel="icon"]');
      if (existingFaviconLink) {
        document.head.removeChild(existingFaviconLink);
      }
    };
  }, []);

  return (
    <Router>
      <ThemeProviderReact>
        <LoadingContextProvider>
          <Helmet>
            <meta name="description" content={title} />
            <link rel="manifest" href={manifestData} />
            <title>{title}</title>
            {/* Add other meta tags as needed */}
          </Helmet>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            limit={2}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            icon={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
          />
          <Routes>
            <Route
              path={PageRoutes.Login}
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.Register}
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.PasswordReset}
              element={
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.VerifyEmail}
              element={
                <PublicRoute>
                  <VerifyEmail />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.HomePage}
              element={
                <PublicRoute>
                  <HomePage />
                </PublicRoute>
              }
            />
            {config?.planpage && (
              <Route
                path={PageRoutes.Packages}
                element={
                  <PublicRoute>
                    <PassesMain isHomePage={false} />
                  </PublicRoute>
                }
              />
            )}
            <Route
              path={PageRoutes.TermAndConditions}
              element={
                <PublicRoute>
                  <TermAndCondition />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.PrivacyPolicy}
              element={
                <PublicRoute>
                  <PrivacyPolicy />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.Setting}
              element={
                <PrivateRoutes>
                  <ProfileSetting />
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Admin}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.SliderSetting}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[
                      UserRoles.HTP_ADMIN,
                      UserRoles.LEAGUE_ADMIN,
                      UserRoles.CUSTOMER_ADMIN,
                    ]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Users}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.PlayerAlerts}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.UserView}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <UserView />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.EditAnnouncement}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <TinyEditor />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />

            <Route
              path={PageRoutes.EditSliderSetting}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[
                      UserRoles.HTP_ADMIN,
                      UserRoles.LEAGUE_ADMIN,
                      UserRoles.CUSTOMER_ADMIN,
                    ]}
                  >
                    <CreateSliderForm />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.EditPlayerAlerts}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <CreatePlayerError />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Alert}
              element={
                <PrivateRoutes>
                  <Alert />
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Any}
              element={
                <PublicRoute>
                  <NoPageFound />
                </PublicRoute>
              }
            />
            {config?.planpage && (
              <Route
                path={PageRoutes.AccountPage}
                element={
                  <PrivateRoutes>
                    <PaymentInformation />
                  </PrivateRoutes>
                }
              />
            )}
            <Route
              path={PageRoutes.EditProfile}
              element={
                <PrivateRoutes>
                  <EditProfile />
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.UpcomingEvent}
              element={
                <PublicRoute>
                  <UpcomingEvent />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.OnDemand}
              element={
                <PublicRoute>
                  <OnDemand />
                </PublicRoute>
              }
            />
            {process.env.REACT_APP_INSIDER == "true" && (
              <Route
                path={PageRoutes.MyNAHLTV}
                element={
                  <PrivateRoutes>
                    <MyNahlTv />
                  </PrivateRoutes>
                }
              />
            )}
            {process.env.REACT_APP_INSIDER == "true" && (
              <Route
                path={PageRoutes.PersonalChannel}
                element={
                  <PrivateRoutes>
                    <PersonalChannelSubscribe />
                  </PrivateRoutes>
                }
              />
            )}
            <Route
              path={PageRoutes.paymentPage}
              element={
                <PrivateRoutes>
                  <Payment />
                </PrivateRoutes>
              }
            />
            {config?.planpage && (
              <Route
                path={PageRoutes.GiftPage}
                element={
                  <PrivateRoutes>
                    <GiftPage />
                  </PrivateRoutes>
                }
              />
            )}
            <Route
              path={PageRoutes.Watch}
              element={
                <PrivateRoutes>
                  <WatchGame />
                </PrivateRoutes>
              }
            />
          </Routes>
        </LoadingContextProvider>
      </ThemeProviderReact>
    </Router>
  );
}

export default App;
