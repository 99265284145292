import {
  Pause,
  PlayArrow,
  Chat,
  Visibility,
  VisibilityOff,
  Info,
  Sync,
  PlusOneOutlined,
  Add,
  Remove,
} from "@mui/icons-material";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  Stack,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  CircularProgress,
  ThemeProvider,
  createTheme,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// @ts-ignore
import WT from "@sscale/wtsdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HokeyPlayIngIcon from "../../../assets/icons/HokeyPlayingIcon";
import BitMovingPlayer from "../../../Common/Component/VideoPlayer/bitMovingPlayer";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import GameCenterBoard from "../../GameCenter";
import ChatComponent from "../ChatComponent";
import ClipsData, { formatTime } from "../ClipsData";
import HighLight from "../HightLight";
import WatchStyle from "../Watch.style";
import { ChatRoom } from "amazon-ivs-chat-messaging";
import ListIcon from "@mui/icons-material/List";
import momentTimezone from "moment-timezone";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { v4 as uuid } from "uuid";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import moment, { duration } from "moment";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
import useConfig from "../../../hooks/useConfig";
import { APIRoutes, CATEGORY, INSIDER_PLAN_ID } from "../../../utils/constants";
import { useParams, useSearchParams } from "react-router-dom";

import { onValue, ref } from "firebase/database";
import { db, makeGetRequest } from "../../../service";
import Login from "../WatchParty/Login/Login";
import { toast } from "react-toastify";
import { Style } from "./PlayerPage.Style";

import { initializeSyncSdk } from "../../../service/SynchronizeService";
import { handleSubmit, off, on } from "../../../service/PubSub";
import { useDispatch } from "react-redux";
import roomDetails from "../../../store/reducers/roomReducer";
import { WatchContex } from "../watch";
import WatchTogether from "../WatchParty/Session/Session";
import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import VideoPlayerForChannel from "../../PersonalChannel/VideoCard/VideoPlayerForChannel";
import MatchCard from "../../PersonalChannel/MatchCard";
import HttpService from "../../../hooks/Https-services";
import { PersonalChannelApi } from "../../PersonalChannel/PersonalChannel.Api";
import LoadingContext from "../../../Common/Contex/LoadingContex";
import Loader from "../../../Common/Component/Loader";
const userTimeZone = momentTimezone.tz.guess();
const VideoPlayerLive = ({ gameDetails, subscriptionDetails }: any) => {
  const [chatRoom, setChatRoom] = useState<any>([]);
  const [isGameSubscribe, setIsGameSubscribe] = useState(false);
  const { gameId }: any = useParams();
  const [videoAway, setVideoAway] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentClip, setCurrentClip] = useState<any>(null);
  const userData = useCommonDetails();
  const [search, setSearch] = useState({ plays: "", hightlight: "" });
  const playerRef = React.useRef<any>("");
  const [isGameCenterLoaded, setIsGameCenterLoaded] = useState(false);
  const classes = Style;
  const videoRef: any = useRef();
  const [liniusAccessToken, setLiniusAccessToken] = useState({} as any);
  const [currentVideoData, setCurrentVideoData] = useState({} as any);
  const [isSeeking, setIsSeeking] = useState(false);

  const [isSkeletonList, setIsSkeletonList] = React.useState(true);
  const [thubmnails, setThumbnails] = useState([] as any);
  const [currentTime, setCurrentTime] = useState(0);
  const playerRefLinius: any = React.useRef(null);
  const [pageData, setPageData] = useState({} as any);
  const [page, setPage] = React.useState<any>(1);
  const [activeClip, setActiveClip] = useState("");
  const configData = useConfig();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    openColleps,
    receivedMessage,
    setReceivedMessage,
    CloseColleps,
  }: any = useContext(LoadingContext);
  const [isDownloadStarted, setIsDownLoadStarted] = useState(false);
  const [isSkeleton, setIsSKeleton] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [gameData, setGameData] = useState({} as any);
  const [isDownloadAll, setIsDownloadAll] = useState(false);
  const [liveData, setLiveData] = useState<any>({});
  const userDetails = useCommonDetails();
  const [homeAway, setHomeAway] = useState("0");
  const [selectedTab, setSelectedTab] = useState("0");
  const [selectedTabAction, setSelectedTabAction] = useState("GOAL");
  const [allDownloadVideo, setAllDownloadVideo] = useState([] as any);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const handleChangeClips = (e: any) => {
    const item: any = { ...currentClip };
    item[e.target.name] = e.target.value;
    setCurrentClip(item);
  };
  const handleTabChangeAction = (event: any, newValue: any) => {
    if (!newValue) {
      return;
    }
    setSelectedTabAction(newValue);
  };

  const handleTabChange = (event: any, newValue: any) => {
    if (!newValue) {
      return;
    }
    if (newValue == "HOME") {
      setHomeAway(gameDetails?.home_team_id_tpe);
    } else {
      setHomeAway(gameDetails?.away_team_id_tpe);
    }
    setSelectedTab(newValue);
  };
  function handleError(error: any) {
    toast(error?.message, { type: "error" });
  }
  const GetAllLinusDownloadVideo = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetAllLinusDownloadVideo);

      setAllDownloadVideo(res.data.data);
    } catch (error) {}
  };
  const { participants, setParticipants } = useContext(WatchContex);
  const getGameDetails = async () => {
    let qurey: any = {
      game_id: gameDetails?.game_id_tts,
    };

    try {
      const res = await makeGetRequest(
        "get_game_center",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: gameDetails.game_id_tts,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${gameDetails.game_id_tts}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      startup_new(array);
      setGameData(data);

      setIsSKeleton(false);
    } catch (error) {
      setIsSKeleton(false);
    }
  };
  const getGameLiveDetails = async () => {
    let qurey: any = {
      game_id: gameDetails?.game_id_tts,
    };

    try {
      const res = await makeGetRequest(
        "get_game_center",
        new URLSearchParams(qurey).toString()
      );

      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      setGameData(data);

      setIsSKeleton(false);
    } catch (error) {
      setIsSKeleton(false);
    }
  };
  const getGameRoster = async () => {
    let qurey: any = {
      game_id: gameDetails.game_id_tts,
    };

    try {
      const res = await makeGetRequest(
        "get_game_rosters",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: gameDetails.game_id_tts,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${gameDetails.game_id_tts}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      setGameData({
        ...gameData,
        away_skaters: res.away_players,
        home_skaters: res.home_players,
      });
    } catch (error) {}
  };
  const getGameEvent = async () => {
    let qurey: any = {
      game_id: gameDetails.game_id_tts,
    };

    try {
      const res = await makeGetRequest(
        "get_game_events",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: gameDetails.game_id_tts,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${gameDetails.game_id_tts}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      setGameData({
        ...gameData,
        events: { [liveData.period.split(" ")[1]]: res.events },
      });
    } catch (error) {}
  };
  function GetRosterData(game_id: any) {
    var xmlHttp: any;

    xmlHttp = new XMLHttpRequest();

    if (xmlHttp == null) {
      alert("Browser does not support Ajax");
    } else {
      xmlHttp.open(
        "GET",
        "http://live-new.sharksice.timetoscore.com" +
          "/get_game_rosters?game_id=" +
          game_id,
        true
      );
      xmlHttp.onreadystatechange = function ClientSideUpdate() {
        var result;

        if (xmlHttp.readyState == 4) {
          result = JSON.parse(xmlHttp.responseText);
          if (result) {
          }
        }
      };

      xmlHttp.send(null);
    }
  }
  function GetGameData(prefix: any, game_id: any) {
    var xmlHttp: any;

    xmlHttp = new XMLHttpRequest();
    if (xmlHttp == null) {
      alert("Browser does not support Ajax");
    } else {
      xmlHttp.open(
        "GET",
        "http://live-new.sharksice.timetoscore.com" +
          "/get_team_info?game_id=" +
          game_id,
        true
      );
      xmlHttp.onreadystatechange = function ClientSideUpdate() {
        var result;
        var oCell;

        if (xmlHttp.readyState == 4) {
          result = JSON.parse(xmlHttp.responseText);
        }
      };

      xmlHttp.send(null);
    }
  }

  function startup_new(channels: any) {
    var i = 0;

    for (i = 0; i < channels.length; i++) {
      /* Firebase mode */
      if (channels[i].game_id > 0)
        var firebase_channel =
          "games/" + channels[i].channel + "/" + channels[i].game_id;
      else var firebase_channel = "rinks/" + channels[i].channel;

      var rink_ref = ref(db, firebase_channel);
      // eslint-disable-next-line no-loop-func
      (function (prefix, game) {
        var last_game_id = 0;
        var requested_game = game;
        if (requested_game) {
          // GetGameData(prefix + "_", requested_game);
          // GetRosterData(requested_game);
          last_game_id = requested_game;
        }

        onValue(rink_ref, (snapshot: any) => {
          const fb_data = snapshot.val();
          if (fb_data == null) return;
          const data = fb_data.clock_data;

          if (fb_data.clock_data == null) return;

          if (requested_game && data.game_id != requested_game) {
            return;
          }

          if (data.rosters == 1) {
            getGameRoster();
          }
          if (data.events == 1) {
            getGameLiveDetails();
          }
          setLiveData(data);
          // process_scoreboard_data(prefix + "_", data);
          if (last_game_id != data.game_id) {
            GetGameData(prefix + "_", data.game_id);
            GetRosterData(data.game_id);
            last_game_id = data.game_id;
          }
        });
      })(channels[i].prefix, channels[i].game_id);
    }
  }
  useEffect(() => {
    if (gameDetails?.game_id_tts) {
      getGameDetails();
    }
  }, [gameDetails]);

  const [name, setName] = useState(
    `${userData.FirstName} ${userData.LastName}`
  );
  const meetingIdFromUrl = searchParams.get("meeting_id");
  const [clipsData, setClipsData] = useState<
    {
      video_clip_uuid: string;
      start_time: number;
      end_time: number;
      name: string;
      category: string;
      game_details: any;
      createdAt: any;
      active: boolean;
      duration: number;
    }[]
  >();
  const [hightLighData, setHighLighData] = useState<
    {
      video_clip_uuid: string;
      start_time: number;
      team_logo_url: any;
      name: string;
      player_first_name: any;
      event_time: any;
      team_short_name: any;
      period: any;
      clip_name: any;
      player_jersey_number: any;
      player_last_name: any;
      player_position: any;
      duration: number;
    }[]
  >();
  const [running, setRunning] = useState(true);
  const [activeIcon, setActiveIcon] = useState<any>({ stats: true });
  function playVideo() {
    setRunning(true);

    playerRef.current.play();
  }

  const [videoList, setVideoList] = useState([]);

  const GetHighLightDetails = useCallback(async () => {
    setIsloading(true);
    try {
      const res = await HttpService.get(
        APIRoutes.GetTeamHighLights.replace(":id", gameDetails?.game_id_tpe)
          .replace(":page", page)
          .replace(":value", selectedTab.toString())
          .replace(":teamData", homeAway)
          .replace(":action", selectedTabAction)
      );

      if (res?.data?.data) {
        setVideoList(res?.data?.data?.content);
        setPageData(res?.data?.data);
        setCurrentVideoData(res?.data?.data?.content[0]);
        setActiveClip(res?.data?.data?.content[0]?.id);
        setIsloading(false);
      }
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
    }
  }, [selectedTab, selectedTabAction, page]);

  const GetHighLightDetailsForOld = async () => {
    try {
      const res = await HttpService.getapi(
        APIRoutes.HighLights.replace(":id", gameId)
      );

      if (res?.data?.data?.length > 0) {
        const setDataSchema = res?.data?.data?.map((el: any) => {
          return {
            video_clip_uuid: el?.uuid,
            start_time: Math.round(el?.start_time),
            team_logo_url: el?.team_logo_url,
            event_time: el?.event_time,
            name: el?.clip_name,
            period: el?.period,
            clip_name: el?.clip_name,
            player_first_name: el?.player_first_name,
            team_short_name: el?.team_short_name,
            player_jersey_number: el?.player_jersey_number,
            player_last_name: el?.player_last_name,
            player_position: el?.player_position,
            duration: el?.clip?.clip_duration,
          };
        });

        setHighLighData(setDataSchema);
      }
    } catch (error) {}
  };

  function pauseVideo() {
    setRunning(false);

    playerRef.current.pause();
  }

  const tokenProvider = async (
    selectedUsername: any,
    isModerator: any,
    avatarUrl: any
  ) => {
    const permissions = isModerator
      ? ["SEND_MESSAGE", "DELETE_MESSAGE", "DISCONNECT_USER"]
      : ["SEND_MESSAGE"];
    const room = await HttpService.post(APIRoutes.CreateRoom, {
      game_id: gameId,
    });

    const data = {
      arn: room.data.data.Arn,
      userId: `${selectedUsername}.${uuid()}`,
      attributes: {
        username: `${selectedUsername}`,
        avatar: `${avatarUrl}`,
      },
      capabilities: permissions,
    };

    let token: any = JSON.parse(localStorage.getItem("token") as any);

    try {
      if (token) {
        if (
          isTokenExpired(token?.tokenExpirationTime) ||
          isTokenExpired(token?.sessionExpirationTime)
        ) {
          const response = await HttpService.post(APIRoutes.GetToken, data);

          const body = JSON.parse(response.data.data.body);
          token = {
            token: body.token,
            sessionExpirationTime: new Date(body.sessionExpirationTime),
            tokenExpirationTime: new Date(body.tokenExpirationTime),
          };
          searchParams.set("id", data.userId);
          setSearchParams(searchParams);
          localStorage.setItem("token", JSON.stringify(token));
        } else {
          token = token;
        }
      } else {
        const response = await HttpService.post(APIRoutes.GetToken, data);

        const body = JSON.parse(response.data.data.body);
        token = {
          token: body.token,
          sessionExpirationTime: new Date(body.sessionExpirationTime),
          tokenExpirationTime: new Date(body.tokenExpirationTime),
        };

        setSearchParams((prev: any) => [
          ...prev.entries(),
          ["id", data.userId],
        ]);

        localStorage.setItem("token", JSON.stringify(token));
      }
    } catch (error) {
      console.error("Error:", error);
    }

    return token;
  };

  const connectRoom = () => {
    // Instantiate a chat room
    let token: any = JSON.parse(localStorage.getItem("token") as any);
    if (token) {
      if (
        isTokenExpired(token?.tokenExpirationTime) ||
        isTokenExpired(token?.sessionExpirationTime)
      ) {
        const room = new ChatRoom({
          regionOrUrl: "us-east-1",
          tokenProvider: () =>
            tokenProvider(
              `${userData?.FirstName} ${userData?.LastName}`,
              false,
              userData?.ProfileImage
            ) as any,
        });

        setChatRoom(room);

        // Connect to the chat room
        room.connect();
      }
    } else {
      const room = new ChatRoom({
        regionOrUrl: "us-east-1",
        tokenProvider: () =>
          tokenProvider(
            `${userData?.FirstName} ${userData?.LastName}`,
            false,
            userData?.ProfileImage
          ) as any,
      });

      setChatRoom(room);

      // Connect to the chat room
      room.connect();
    }
  };
  const findCurrentClipTime = (el: any, index: any) => {
    let sum = 0;
    for (var i = 0; i < index; i++) {
      sum += el?.duration;
    }
    return sum;
  };
  function isTokenExpired(expirationDate: any) {
    const currentDate = moment();
    const tokenExpirationDate = moment(expirationDate);

    return tokenExpirationDate.isBefore(currentDate);
  }
  const handleChangeIcon = (name: any) => {
    const item: any = {};
    item[name] = true;
    setIsEdit(false);
    setCurrentClip(null);
    setActiveIcon({ ...item });
  };

  const jumpToTime = (time: any) => {
    if (playerRef.current) {
      const maxTime = playerRef.current.getMaxTimeShift();
      if (playerRef.current.isLive() || gameData?.is_live) {
        playerRef.current.timeShift(maxTime + time);
      } else {
        playerRef.current.seek(time);
      }
    }
  };
  const handleClipPost = async (name: string) => {
    try {
      const unique_id = uuid();
      const clips = clipsData || [];
      const currentPosition = Math.round(
        playerRef.current.getCurrentTime("relativetime")
      );

      const start_time = currentPosition - 5;
      const end_time = start_time + 20;
      const postClip = {
        video_clip_uuid: unique_id,
        start_time: start_time <= 0 ? 0 : start_time,
        end_time: end_time,
        name: name,
        category: name,
        duration: 20,
        active: true,
        createdAt: moment(),
        game_details: {
          video_url:
            videoAway && gameDetails?.watch_url_away
              ? gameDetails?.watch_url_away
              : gameDetails?.watch_url,
          vod_url:
            videoAway && gameDetails?.vod_url_away
              ? gameDetails?.vod_url_away
              : gameDetails?.vod_url,
          game_id: gameId,
          away_team: gameDetails.away_team,
          home_team: gameDetails.home_team,
          game_date_time: gameDetails.game_date_time,
        },
      };

      clips?.push(postClip);
      const data = {
        game_id: gameId,
        videoUrl:
          videoAway && gameDetails?.watch_url_away
            ? gameDetails?.watch_url_away
            : gameDetails?.watch_url,
        unique_id: unique_id,
        clips: clips,
        name: `Created ${name}`,
        status: "CREATE",
      };

      const res = await HttpService.post(APIRoutes.PostClipDetailas, data);
      GetClipsDetails();
      handleChangeIcon("plays");
    } catch (error) {}
  };
  const handleDelete = async (video_clip_uuid: any) => {
    try {
      const clips = clipsData || [];

      const postClip: any = clips?.findIndex(
        (el) => el.video_clip_uuid == video_clip_uuid
      );
      const name = clips[postClip].name;
      clips.splice(postClip, 1);

      const data = {
        game_id: gameId,
        clips: clips,
        name: `Deleted ${name}`,
        status: "DELETE",
      };

      const res = await HttpService.post(APIRoutes.PostClipDetailas, data);
      GetClipsDetails();
    } catch (error) {}
  };

  const handleSubmitData = async (value: any, video_clip_uuid: any) => {
    if (!value.name) {
      toast.error("Name is required");
      return;
    }
    if (value?.duration < 20 || value?.duration > 60) {
      toast.error(
        "Duration should be less than 1 min and greater than 20 sec "
      );
      return;
    }
    try {
      const clips = clipsData || [];
      const unique_id = uuid();
      const postClip: any = clips?.findIndex(
        (el) => el.video_clip_uuid == video_clip_uuid
      );
      const name = clips[postClip].name;

      clips[postClip].active = false;

      const data2 = {
        game_id: gameId,
        clips: clips,
        name: `Deleted ${name}`,
        status: "DELETE",
      };

      const res2 = await HttpService.post(APIRoutes.PostClipDetailas, data2);

      const postClip2 = {
        video_clip_uuid: unique_id,
        start_time: value.start_time,
        end_time: value.end_time,
        name: value?.name,
        category: value?.category,
        duration: value.end_time - value.start_time || 20,
        active: true,
        createdAt: moment(),
        game_details: {
          video_url:
            videoAway && gameDetails?.watch_url_away
              ? gameDetails?.watch_url_away
              : gameDetails?.watch_url,
          game_id: gameId,
          away_team: gameDetails.away_team,
          home_team: gameDetails.home_team,
          game_date_time: gameDetails.game_date_time,
        },
      };

      clips?.push(postClip2);
      const data = {
        game_id: gameId,
        videoUrl:
          videoAway && gameDetails?.watch_url_away
            ? gameDetails?.watch_url_away
            : gameDetails?.watch_url,
        unique_id: unique_id,
        clips: clips,
        name: `Created ${name}`,
        status: "CREATE",
      };

      const res = await HttpService.post(APIRoutes.PostClipDetailas, data);

      GetClipsDetails();
      setIsEdit(false);
    } catch (error) {}
  };

  const skipForward = (time: any) => {
    if (playerRef.current) {
      const currentPosition = playerRef.current.getCurrentTime("relativetime");
      const currentPositionLive = playerRef.current.getMaxTimeShift();

      if (playerRef.current.isLive() || gameData?.is_live) {
        if (-currentPosition - currentPositionLive > time) {
          playerRef.current.timeShift(
            currentPositionLive + currentPosition + time
          );
        }
      } else {
        playerRef.current.seek(currentPosition + time);
      }
    }
  };

  const rewind = (time: any) => {
    if (playerRef.current) {
      const currentPosition = playerRef.current.getCurrentTime("relativetime");
      const currentPositionLive = playerRef.current.getMaxTimeShift();
      if (playerRef.current.isLive() || gameData?.is_live) {
        playerRef.current.timeShift(
          currentPositionLive + currentPosition - time
        );
      } else {
        playerRef.current.seek(currentPosition - time);
      }
    }
  };
  const getDuration = () => {
    return currentClip.end_time - currentClip.start_time;
  };
  const GetClipsDetails = async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetVideoClipsDetails.replace(":id", gameId)
      );
      setClipsData(res.data.data);
    } catch (error) {}
  };
  const dispatch = useDispatch();
  const startConnecting = async () => {
    try {
      const meeting_id = meetingIdFromUrl;

      const meetingDetails = await HttpService.post(APIRoutes.JoinParty, {
        meetingId: meeting_id,
      });

      const connect = false ? WT.Session.connectAsAViewer : WT.Session.connect;
      const room = {
        token: meetingDetails?.data?.data?.SyncToken,
        clientName: `${userDetails.FirstName} ${userDetails.LastName}`,
        video:
          videoAway && gameDetails?.watch_url_away
            ? gameDetails?.watch_url_away
            : gameDetails?.watch_url,
      };

      connect(
        meetingDetails?.data?.data.WTToken,
        `${userDetails.FirstName} ${userDetails.LastName}`,
        {
          audio: true,
          video: true,
        }
      );

      dispatch(roomDetails.actions.setRoomDetails(room));
      handleSubmit(
        meetingDetails?.data?.data?.SyncToken,
        `${userDetails.FirstName} ${userDetails.LastName}`
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    GetClipsDetails();
    initializeSyncSdk();
    GetHighLightDetailsForOld();
    if (process.env.REACT_APP_FEATURE == "true") {
      GetAccessToken();
    }
    if (searchParams.get("meeting_id")) {
      handleChangeIcon("watch");
      startConnecting();
    } else {
      dispatch(roomDetails.actions.removeRoomDetails());
    }
    on("error", handleError);

    return () => {
      off("error", handleError);
    };
  }, []);
  const handleDurationChange = (dur: number, isStart: boolean) => {
    if (
      (!isStart &&
        (currentClip.end_time - currentClip.start_time + dur < 20 ||
          currentClip.end_time - currentClip.start_time + dur > 60)) ||
      (isStart &&
        (currentClip.end_time - currentClip.start_time - dur < 20 ||
          currentClip.end_time - currentClip.start_time - dur > 60))
    ) {
      toast.error("Duration should be less than 1 min and greater than 20 sec");
      return;
    }
    if (currentClip) {
      if (isStart) {
        setCurrentClip({
          ...currentClip,
          start_time:
            currentClip.start_time + dur <= 0
              ? 0
              : currentClip.start_time + dur,
        });
      } else {
        setCurrentClip({
          ...currentClip,
          end_time: currentClip.end_time + dur,
        });
      }
    }
  };
  const GetAccessToken = async () => {
    try {
      const res = await PersonalChannelApi.GetLiniusAccessToken(
        APIRoutes.GetLiniusAccessToken
      );
      if (res?.data?.data) {
        localStorage.setItem(
          "persist:root",
          JSON.stringify({
            authSlice: JSON.stringify({
              accessToken: res?.data?.data.accessToken,
            }),
            userSlice: JSON.stringify({
              apiKey: configData.xApiKey,
            }),
          })
        );
        setLiniusAccessToken(res?.data?.data);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    if (process.env.REACT_APP_FEATURE == "true") {
      GetHighLightDetails();
      GetAllLinusDownloadVideo();
    }
  }, [GetHighLightDetails]);
  return (
    <Box>
      <WatchTogether />
      <Grid container spacing={1}>
        <Grid item xs={12} md={8.5}>
          <Box sx={{ p: 1 }}>
            {activeIcon?.highlight &&
            process.env.REACT_APP_FEATURE == "true" ? (
              <VideoPlayerForChannel
                page={page}
                pageData={pageData}
                setActiveClip={setActiveClip}
                setPage={setPage}
                setCurrentTime={setCurrentTime}
                playerRef={playerRefLinius}
                videoRef={videoRef}
                currentVideoData={currentVideoData}
                setCurrentVideoData={setCurrentVideoData}
                videoList={videoList}
                assetId={currentVideoData?.assetId}
                offset={currentVideoData?.offset}
                duration={currentVideoData?.duration}
                accessToken={liniusAccessToken?.accessToken}
                xApiKey={liniusAccessToken?.xApiKey}
              />
            ) : (
              <Box>
                <BitMovingPlayer
                  gameDetails={gameDetails}
                  setRunning={setRunning}
                  setIsSeeking={() => setIsSeeking(!isSeeking)}
                  videoAway={videoAway}
                  videoUrl={
                    videoAway && gameDetails?.watch_url_away
                      ? gameDetails?.watch_url_away
                      : gameDetails?.watch_url
                  }
                  playerRef={playerRef}
                  autoPlay={true}
                />
              </Box>
            )}
          </Box>

          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            {gameDetails?.watch_url_away && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    ml: 1,
                    justifyContent: "center",
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      sx={{ textTransform: "none" }}
                    >
                      Home Feed
                    </Typography>
                    <Switch
                      checked={videoAway}
                      onChange={(e: any) => {
                        setVideoAway(e.target.checked);
                      }}
                    />
                    <Typography
                      fontWeight="600"
                      variant="body2"
                      sx={{ textTransform: "none" }}
                    >
                      Away Feed
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            )}
            <Grid item xs={1.8}>
              <button
                onClick={() => rewind(10)}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                <FastRewindIcon />
                {"10"}
              </button>
            </Grid>
            <Grid item xs={1.8}>
              <button
                onClick={() => rewind(3)}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                <FastRewindIcon />
                {"3"}
              </button>
            </Grid>
            <Grid item xs={1.8}>
              <button
                onClick={() => rewind(1)}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                <FastRewindIcon />
                {"1"}
              </button>
            </Grid>
            <Grid item xs={1}>
              {running ? (
                <button
                  className={`video-button ${configData.buttonCommonClass}`}
                  style={{ fontWeight: "800" }}
                  onClick={() => pauseVideo()}
                >
                  <Pause />
                </button>
              ) : (
                <button
                  className={`video-button ${configData.buttonCommonClass}`}
                  style={{ fontWeight: "800" }}
                  onClick={() => playVideo()}
                >
                  <PlayArrow />
                </button>
              )}
            </Grid>
            <Grid item xs={1.8}>
              <button
                onClick={() => skipForward(1)}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                <FastForwardIcon /> {"1"}
              </button>
            </Grid>
            <Grid item xs={1.8}>
              <button
                onClick={() => skipForward(3)}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                <FastForwardIcon />
                {"3"}
              </button>
            </Grid>{" "}
            <Grid item xs={1.8}>
              <button
                onClick={() => skipForward(10)}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                <FastForwardIcon /> {"10"}
              </button>
            </Grid>
          </Grid>

          <Grid sx={{ p: 1, mt: 1 }} spacing={1.5} container>
            <Grid item xs={6} sm={4} md={3}>
              <button
                onClick={() => {
                  handleClipPost("goal");
                }}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                Goal
              </button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <button
                onClick={() => {
                  handleClipPost("shot");
                }}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                Shot
              </button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <button
                onClick={() => {
                  handleClipPost("save");
                }}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                Save
              </button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <button
                onClick={() => {
                  handleClipPost("highlight");
                }}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                Highlight
              </button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <button
                onClick={() => {
                  handleClipPost("faceoff");
                }}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                Faceoff
              </button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <button
                onClick={() => {
                  handleClipPost("hit");
                }}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                Hit
              </button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <button
                onClick={() => {
                  handleClipPost("penalty");
                }}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                Penalty
              </button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <button
                onClick={() => {
                  handleClipPost("custom");
                }}
                className={`video-button ${configData.buttonCommonClass}`}
              >
                Custom
              </button>
            </Grid>
          </Grid>

          <Box
            sx={{
              textAlign: "start",
              display: "flex",
              alignItems: "center",
              py: 3,
              gap: 2,
            }}
          >
            <Box>
              <img width={"100px"} src={gameDetails?.card_logo} />
            </Box>

            <Box>
              {!gameDetails?.card_image_url && (
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 600,
                    color: configData.primary,
                  }}
                >
                  {" "}
                  {gameDetails.away_team} @ {gameDetails.home_team}
                </Typography>
              )}

              <Typography
                variant="body2"
                sx={{
                  color: configData.primary,
                  fontWeight: 600,
                  fontStyle: "normal",
                }}
              >
                {gameDetails?.card_description}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: configData.primary,
                  fontWeight: 600,
                  fontStyle: "normal",
                }}
              >
                {" "}
                {momentTimezone
                  .tz(
                    gameDetails.game_date_time,
                    "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                    "America/New_York"
                  )
                  .tz(userTimeZone)
                  .format("h:mm a z")}{" "}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={3.5}>
          <Box
            sx={{
              px: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              bgcolor: configData.primary,
              height: { xs: "auto", md: "600px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: {
                  xs: "space-between",
                  md: "flex-start",
                },
                alignItems: "center",
              }}
            >
              {" "}
              <Box
                onClick={() => {
                  handleChangeIcon("stats");
                }}
                sx={WatchStyle.ItemStyle}
              >
                <IconButton>
                  <ListIcon
                    sx={{
                      color: activeIcon?.stats ? "white" : "grey",
                      fontSize: "30px",
                    }}
                  />
                </IconButton>{" "}
                <Typography
                  sx={{
                    color: activeIcon?.stats ? "white" : "grey",
                    fontSize: "10px",
                  }}
                  variant="caption"
                >
                  STATS
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  handleChangeIcon("plays");
                }}
                sx={WatchStyle.ItemStyle}
              >
                <IconButton>
                  <WatchLaterOutlinedIcon
                    sx={{
                      color: activeIcon?.plays ? "white" : "grey",
                      fontSize: "30px",
                    }}
                  />
                </IconButton>{" "}
                <Typography
                  sx={{
                    color: activeIcon?.plays ? "white" : "grey",
                    fontSize: "10px",
                  }}
                  variant="caption"
                >
                  PLAYS
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  handleChangeIcon("highlight");
                }}
                sx={WatchStyle.ItemStyle}
              >
                <IconButton>
                  <HokeyPlayIngIcon
                    sx={{
                      color: activeIcon?.highlight ? "white" : "grey",
                      fontSize: "30px",
                    }}
                  />
                </IconButton>{" "}
                <Typography
                  sx={{
                    color: activeIcon?.highlight ? "white" : "grey",
                    fontSize: "10px",
                  }}
                  variant="caption"
                >
                  HIGHLIGHTS
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  handleChangeIcon("chat");
                  connectRoom();
                }}
                sx={WatchStyle.ItemStyle}
              >
                <IconButton>
                  <Chat
                    sx={{
                      color: activeIcon?.chat ? "white" : "grey",
                      fontSize: "30px",
                    }}
                  />
                </IconButton>{" "}
                <Typography
                  sx={{
                    color: activeIcon?.chat ? "white" : "grey",
                    fontSize: "10px",
                  }}
                  variant="caption"
                >
                  CHAT
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  handleChangeIcon("watch");
                }}
                sx={WatchStyle.ItemStyle}
              >
                <IconButton sx={{ py: 0.6 }}>
                  <FontAwesomeIcon
                    icon={faPeopleArrows}
                    style={{
                      color: activeIcon?.watch ? "white" : "grey",
                      fontSize: "22px",
                      marginBottom: "8px",
                      marginTop: "6px",
                    }}
                  />
                </IconButton>{" "}
                <Typography
                  sx={{
                    color: activeIcon?.watch ? "white" : "grey",
                    fontSize: "10px",
                  }}
                  variant="caption"
                >
                  WATCH
                </Typography>
              </Box>
            </Box>
            {activeIcon?.stats && (
              <Box>
                {gameDetails?.stats_embed ? (
                  <Box>
                    <iframe
                      src={gameDetails?.stats_embed}
                      style={{
                        width: "100%",
                        height: "550px",
                      }}
                    ></iframe>{" "}
                  </Box>
                ) : gameDetails?.game_id_tts != null ? (
                  <Box>
                    <GameCenterBoard gameData={gameData} liveData={liveData} />
                  </Box>
                ) : (
                  <Typography variant="body2">
                    No data is available for this game.
                  </Typography>
                )}
              </Box>
            )}
            {activeIcon?.plays && (
              <Box sx={{ p: 1, background: "lightGray", flexGrow: 0.98 }}>
                {!isEdit && (
                  <Box sx={{ py: 1 }}>
                    <TextField
                      size="small"
                      placeholder="Filter"
                      onChange={(e: any) =>
                        setSearch({
                          ...search,
                          plays: e.target.value,
                        })
                      }
                      autoComplete="off"
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DADADA",
                          },
                        },
                        input: {
                          fontSize: "14px",
                          fontWeight: 600,
                        },
                      }}
                    />
                  </Box>
                )}

                {isEdit ? (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        type={"text"}
                        name="name"
                        onChange={handleChangeClips}
                        value={currentClip.name}
                        autoComplete="off"
                        size="small"
                        placeholder="Clip Name"
                        sx={{
                          input: {
                            color: "black",
                            fontSize: "14px",
                            fontWeight: 600,
                            textTransform: "capitalize",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DADADA",
                            },
                          },
                        }}
                        defaultValue={currentClip?.name}
                        variant="outlined"
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          fontStyle: "normal",
                          textTransform: "none",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Clip Length:{" "}
                        {currentClip?.end_time - currentClip?.start_time || 20}{" "}
                        secs
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        my: 2,

                        alignItems: "center",
                      }}
                    >
                      <TextField
                        label="Start Time"
                        size="small"
                        disabled
                        sx={{
                          input: {
                            fontSize: "14px",
                          },
                          label: {
                            fontSize: "14px",
                          },
                        }}
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="end">
                        //       <Tooltip
                        //         title="Sync with video player"
                        //         arrow
                        //         placement="top"
                        //       >
                        //         <IconButton
                        //           onClick={() => {
                        //             if (currentClip) {
                        //               setCurrentClip({
                        //                 ...currentClip,
                        //                 start_time: Math.round(
                        //                   playerRef.current.getCurrentTime(
                        //                     "relativetime"
                        //                   )
                        //                 ),
                        //                 end_time:
                        //                   Math.round(
                        //                     playerRef.current.getCurrentTime(
                        //                       "relativetime"
                        //                     )
                        //                   ) + currentClip?.duration,
                        //               });
                        //             }
                        //           }}
                        //           aria-label="toggle password visibility"
                        //           edge="end"
                        //         >
                        //           <Sync />
                        //         </IconButton>
                        //       </Tooltip>
                        //     </InputAdornment>
                        //   ),
                        // }}
                        value={formatTime(currentClip?.start_time)}
                      />
                      <TextField
                        label="End Time"
                        size="small"
                        disabled
                        value={formatTime(currentClip?.end_time)}
                        sx={{
                          input: {
                            fontSize: "14px",
                          },
                          label: {
                            fontSize: "14px",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        mt: 1,

                        alignItems: "center",
                      }}
                    >
                      <Stack direction={"row"} alignItems={"center"}>
                        <Stack>
                          <Tooltip
                            title={
                              getDuration() <= 20
                                ? "Duration should be greater than 20 sec"
                                : "Increase start time by 1 second"
                            }
                            arrow
                            placement="left"
                          >
                            <span>
                              <IconButton
                                onClick={() => handleDurationChange(1, true)}
                                disabled={getDuration() <= 20 ? true : false}
                                sx={{
                                  pb: 0,
                                  borderBottomLeftRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                              >
                                <ArrowDropUpIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={
                              getDuration() >= 60
                                ? "Duration should be less than 1 min"
                                : "Decrease start time by 1 second"
                            }
                            arrow
                            placement="left"
                          >
                            <span>
                              <IconButton
                                onClick={() => handleDurationChange(-1, true)}
                                disabled={getDuration() >= 60 ? true : false}
                                sx={{
                                  pt: 0,
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                }}
                              >
                                <ArrowDropDownIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Stack>
                        <Stack direction={"row"} gap={0.5}>
                          <Tooltip
                            title={
                              getDuration() >= 60
                                ? "Duration should be less than 1 min"
                                : "Decrease start time by 10 seconds"
                            }
                            arrow
                            placement="top"
                          >
                            <span>
                              <Button
                                variant="outlined"
                                sx={{ px: 0.5, minWidth: 0 }}
                                disabled={getDuration() >= 60 ? true : false}
                                onClick={() => handleDurationChange(-10, true)}
                              >
                                <Remove sx={{ fontSize: "16px" }} />
                                10
                              </Button>
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={
                              getDuration() <= 20
                                ? "Duration should be greater than 20 sec"
                                : "Increase start time by 10 seconds"
                            }
                            arrow
                            placement="top"
                          >
                            <span>
                              <Button
                                variant="outlined"
                                disabled={getDuration() <= 20 ? true : false}
                                onClick={() => handleDurationChange(10, true)}
                                sx={{ px: 0.5, minWidth: 0 }}
                              >
                                10
                                <Add sx={{ fontSize: "16px" }} />
                              </Button>
                            </span>
                          </Tooltip>
                        </Stack>
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Stack direction={"row"} gap={0.5}>
                          <Tooltip
                            title={
                              getDuration() <= 20
                                ? "Duration should be greater than 20 sec"
                                : "Decrease end time by 10 seconds"
                            }
                            arrow
                            placement="top"
                          >
                            <span>
                              <Button
                                variant="outlined"
                                disabled={getDuration() <= 20 ? true : false}
                                sx={{ px: 0.5, minWidth: 0 }}
                                onClick={() => handleDurationChange(-10, false)}
                              >
                                <Remove sx={{ fontSize: "16px" }} />
                                10
                              </Button>
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={
                              getDuration() >= 60
                                ? "Duration should be less than 1 min"
                                : "Increase end time by 10 seconds"
                            }
                            arrow
                            placement="top"
                          >
                            <span>
                              <Button
                                variant="outlined"
                                onClick={() => handleDurationChange(10, false)}
                                disabled={getDuration() >= 60 ? true : false}
                                sx={{ px: 0.5, minWidth: 0 }}
                              >
                                10
                                <Add sx={{ fontSize: "16px" }} />
                              </Button>
                            </span>
                          </Tooltip>
                        </Stack>
                        <Stack>
                          <Tooltip
                            disableFocusListener
                            title={
                              getDuration() >= 60
                                ? "Duration should be less than 1 min"
                                : "Increase end time by 1 second"
                            }
                            arrow
                            placement="right"
                          >
                            <span>
                              <IconButton
                                onClick={() => handleDurationChange(1, false)}
                                disabled={getDuration() >= 60 ? true : false}
                                sx={{
                                  pb: 0,
                                  borderBottomLeftRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                              >
                                <ArrowDropUpIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={
                              getDuration() <= 20
                                ? "Duration should be greater than 20 sec"
                                : "Decrease end time by 1 second"
                            }
                            arrow
                            placement="right"
                          >
                            <span>
                              <IconButton
                                onClick={() => handleDurationChange(-1, false)}
                                disabled={getDuration() <= 20 ? true : false}
                                sx={{
                                  pt: 0,
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                }}
                              >
                                <ArrowDropDownIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        mt: 1,

                        alignItems: "center",
                      }}
                    >
                      <Select
                        size="small"
                        name="category"
                        value={currentClip?.category || "-1"}
                        sx={{ fontSize: "14px" }}
                        defaultValue={currentClip?.category || "-1"}
                        onChange={handleChangeClips}
                      >
                        <MenuItem value="-1" sx={{ display: "none" }}>
                          Select Category{" "}
                        </MenuItem>
                        {CATEGORY?.map((el: any, index: number) => {
                          return (
                            <MenuItem key={index + 1} value={el.value}>
                              {el?.key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 0.5,
                          alignItems: "center",
                        }}
                      >
                        <button
                          onClick={() => {
                            handleSubmitData(
                              currentClip,
                              currentClip?.video_clip_uuid
                            );
                          }}
                          style={{ fontSize: "14px", padding: "4px 8px" }}
                          className={configData.buttonCommonClass}
                        >
                          Save
                        </button>

                        <button
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "14px",
                            padding: "3px",
                            border: "1px solid",
                            borderColor: configData.primary,
                            color: configData.primary,
                          }}
                          onClick={() => {
                            setCurrentClip(null);
                            setIsEdit(false);
                          }}
                          className={configData.buttonCommonClass}
                        >
                          Cancel
                        </button>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{
                          mt: 3,
                          mx: 2,
                          fontStyle: "normal",
                          textTransform: "none",
                        }}
                      >
                        To edit your clip's duration and details, use the tools
                        above. Max clip length: 1 min
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  clipsData
                    ?.sort((a, b) => (a?.start_time > b?.start_time ? 1 : -1))
                    ?.filter((el) =>
                      el?.name
                        ?.toLowerCase()
                        ?.includes(search?.plays?.toLowerCase())
                    )
                    .filter((el) => el.active)
                    ?.map((el) => (
                      <ClipsData
                        el={el}
                        setCurrentClip={setCurrentClip}
                        isDownloadStarted={isDownloadStarted}
                        setIsDownLoadStarted={setIsDownLoadStarted}
                        GetClipsDetails={() => GetClipsDetails()}
                        handleDelete={(video_clip_uuid: any) => {
                          handleDelete(video_clip_uuid);
                        }}
                        isLive={gameDetails?.is_live}
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                        handleSubmitData={(value: any, video_clip_uuid: any) =>
                          handleSubmitData(value, video_clip_uuid)
                        }
                        subscriptionDetails={subscriptionDetails}
                        videoUrl={
                          videoAway && gameDetails?.watch_url_away
                            ? gameDetails?.watch_url_away
                            : gameDetails?.watch_url
                        }
                        vod_url={
                          videoAway && gameDetails?.vod_url_away
                            ? gameDetails?.vod_url_away
                            : gameDetails?.vod_url
                        }
                        jumptoTime={() => jumpToTime(el?.start_time)}
                      />
                    ))
                )}
              </Box>
            )}
            {activeIcon?.highlight &&
              (process.env.REACT_APP_FEATURE != "true" ? (
                <Box sx={{ p: 1 }}>
                  <Box sx={{ py: 1 }}>
                    <TextField
                      size="small"
                      placeholder="Filter"
                      onChange={(e: any) =>
                        setSearch({
                          ...search,
                          hightlight: e.target.value,
                        })
                      }
                      autoComplete="off"
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DADADA",
                          },
                        },
                        input: {
                          color: "white",
                          fontSize: "14px",
                          fontWeight: 600,
                        },
                      }}
                    />
                  </Box>

                  {hightLighData ? (
                    hightLighData
                      ?.sort((a, b) => (a?.start_time > b?.start_time ? 1 : -1))
                      ?.filter((el) =>
                        el?.name
                          ? el?.name
                              ?.toLowerCase()
                              ?.includes(search?.hightlight?.toLowerCase())
                          : el
                      )
                      ?.map((el) => (
                        <HighLight
                          el={el}
                          hide={true}
                          handleDelete={(video_clip_uuid: any) => {
                            handleDelete(video_clip_uuid);
                          }}
                          handleSubmitData={(
                            value: any,
                            video_clip_uuid: any
                          ) => handleSubmitData(value, video_clip_uuid)}
                          jumptoTime={() => jumpToTime(el?.start_time)}
                        />
                      ))
                  ) : (
                    <Typography variant="body2" sx={{ color: "white" }}>
                      There are currently no highlights for this game.
                    </Typography>
                  )}
                </Box>
              ) : (
                <Box sx={{ p: 1 }}>
                  <Box>
                    <button
                      onClick={() => handleChangeIcon("stats")}
                      className={configData.buttonCommonClass}
                    >
                      Go Back
                    </button>
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        mb: 1,
                      }}
                    >
                      <ToggleButtonGroup
                        value={selectedTabAction}
                        exclusive
                        onChange={handleTabChangeAction}
                        aria-label="tabs"
                        sx={{
                          whiteSpace: "nowrap",
                          width: "100%",
                          ".MuiToggleButton-root": {
                            flex: 1,
                            fontSize: "10px",
                            color: "black",
                            textTransform: "none",
                            opacity: 1,
                          },
                        }}
                        size="small"
                      >
                        <ToggleButton
                          value={"GOAL"}
                          aria-label="Tab 1"
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "white",
                              backgroundColor: configData.secondary,
                            },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          selected={selectedTabAction == "GOAL"}
                        >
                          GOALS
                        </ToggleButton>
                        <ToggleButton
                          value={"HIT*"}
                          aria-label="Tab 1"
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "white",
                              backgroundColor: configData.secondary,
                            },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          selected={selectedTabAction == "HIT*"}
                        >
                          HITS
                        </ToggleButton>
                        <ToggleButton
                          value={"SAVE*"}
                          aria-label="Tab 1"
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "white",
                              backgroundColor: configData.secondary,
                            },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          selected={selectedTabAction == "SAVE*"}
                        >
                          SAVES
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        mb: 1,
                      }}
                    >
                      <ToggleButtonGroup
                        value={selectedTab}
                        exclusive
                        onChange={handleTabChange}
                        aria-label="tabs"
                        sx={{
                          whiteSpace: "nowrap",
                          width: "100%",
                          ".MuiToggleButton-root": {
                            flex: 1,
                            fontSize: "10px",
                            color: "black",
                            textTransform: "none",
                            opacity: 1,
                          },
                        }}
                        size="small"
                      >
                        <ToggleButton
                          value={"AWAY"}
                          aria-label="Tab 1"
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "white",
                              backgroundColor: configData.secondary,
                            },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          selected={selectedTab == "AWAY"}
                        >
                          {gameDetails.away_team_short}
                        </ToggleButton>
                        <ToggleButton
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "white",
                              backgroundColor: configData.secondary,
                            },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          value={"HOME"}
                          selected={selectedTab == "HOME"}
                          aria-label="Tab 2"
                        >
                          {gameDetails.home_team_short}
                        </ToggleButton>
                        <ToggleButton
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "white",
                              backgroundColor: configData.secondary,
                            },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          value="0"
                          aria-label="Tab 3"
                          selected={selectedTab == "0"}
                        >
                          All
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>

                    {videoList?.length > 0 ? (
                      <Box>
                        <Stack
                          sx={{
                            my: 1,
                            mx: "auto",
                            width: 1,
                            justifyContent: "center",
                          }}
                          spacing={2}
                        >
                          <ThemeProvider
                            theme={createTheme({
                              palette: {
                                mode: "dark",
                              },
                            })}
                          >
                            <Pagination
                              onChange={handleChange}
                              color="standard"
                              page={page}
                              sx={{
                                mb: 1,
                                mt: 1,
                                width: 1,
                                mx: "auto",
                                justifyContent: "center !important",
                              }}
                              count={pageData?.totalPages}
                            />
                          </ThemeProvider>
                        </Stack>
                        {isLoading ? (
                          <CircularProgress />
                        ) : (
                          videoList?.map((el: any, index: any) => (
                            <Box
                              onClick={() => {
                                playerRefLinius.current.currentTime(
                                  findCurrentClipTime(el, index)
                                );
                              }}
                            >
                              <MatchCard
                                isHighLight={true}
                                isDownloadAll={isDownloadAll}
                                setIsDownloadAll={setIsDownloadAll}
                                allDownloadVideo={allDownloadVideo}
                                subscriptionDetails={subscriptionDetails}
                                el={el}
                                active={activeClip == el?.id}
                                thubmnails={thubmnails[index]}
                              />
                            </Box>
                          ))
                        )}
                      </Box>
                    ) : (
                      // <HighLight
                      //   el={el}
                      //   hide={true}
                      //   jumptoTime={() => jumpToTime("")}
                      // />

                      <Typography variant="body2" sx={{ color: "white" }}>
                        There are currently no highlights for this game.
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}

            <Box
              sx={{
                flexGrow: 1,
                py: 1,
                height: "100%",
                display: activeIcon?.chat ? "block" : "none",
              }}
            >
              <ChatComponent chatRoom={chatRoom} />
            </Box>

            <Box
              sx={{
                position: "relative",
                display: activeIcon?.watch ? "flex" : "none",
                flexDirection: "column",
                justifyContent: "space-between",
                height: 1,
              }}
            >
              <Login
                videoUrl={
                  videoAway && gameDetails?.watch_url_away
                    ? gameDetails?.watch_url_away
                    : gameDetails?.watch_url
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VideoPlayerLive;
