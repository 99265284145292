import { useEffect, useMemo, useState } from "react";
import {
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  MRT_TableContainer,
} from "material-react-table";
import React from "react";
import ImageDialogBox from "./dialog";
import StatusBox from "./statusBox";
import HttpService from "../../../../hooks/Https-services";
import { APIRoutes } from "../../../../utils/constants";

const Example = ({ initData }: any) => {
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    //column definitions...
    () => [
      {
        accessorKey: "Name",
        header: "Name",
      },
      {
        accessorKey: "Image",
        header: "Image",

        Cell(props) {
          return <ImageDialogBox src={props.renderedCellValue} />;
        },
      },

      {
        accessorKey: "channel_name",

        header: "Status",
        Cell(props) {
          return <StatusBox data={props} />;
        },
      },
    ],
    []
    //end
  );

  const [data, setData] = useState(initData);
  const handleUpdateAllSlider = async (slider: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.updateAllSlider.replace(":status", "true"),
        {
          data: slider,
        }
      );
    } catch (error) {}
  };
  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    muiRowDragHandleProps: ({ table }: any) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            (hoveredRow as MRT_Row<any>).index,
            0,
            data.splice(draggingRow.index, 1)[0]
          );
          handleUpdateAllSlider(data);
          setData([...data]);
        }
      },
    }),
  });
  useEffect(() => {
    setData(initData);
  }, [initData]);
  return <MRT_TableContainer table={table} />;
};

export default Example;
