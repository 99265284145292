import { useLocation } from "react-router-dom";
import ConfigData from "./../Config/config.json";
const useConfig = (): {
  logoUrl: string;
  headerLogo: string;
  giftPageLogo: string;
  paymentPageLogo: string;
  planpage: boolean;
  customer: string;
  name: string;
  title: string;
  subtitle: string;
  primary: string;
  secondary: string;
  returnUrl: string;
  tertiary: string;
  privacyText: string;
  stripePublishableKey: string;
  stripeSecretKey: string;
  gTag: string;
  api_url: string;
  supportUrl: string;
  buttonCommonClass: string;
  manageButtonClass: string;
  favicon: string;
  personalChannel: string;
  mainName: string;
  jwPlayerID: string;
  privacyLink: string;
  s3HostUrl: string;
  planHeader: string;
  liniusUrl: string;
  bitMovinKey: string;
  analysticBitmovinKey: string;
  ad_url?: string;
  xApiKey: string;
} => {
  const location = useLocation();
  const data: {
    [key: string]: {
      logoUrl: string;
      headerLogo: string;
      giftPageLogo: string;
      paymentPageLogo: string;
      planpage: boolean;
      customer: string;
      name: string;
      primary: string;
      secondary: string;
      tertiary: string;
      favicon: string;
      personalChannel: string;
      mainName: string;
      returnUrl: string;
      manageButtonClass: string;
      buttonCommonClass: string;
      privacyLink: string;
      title: string;
      supportUrl: string;
      subtitle: string;
      stripePublishableKey: string;
      stripeSecretKey: string;
      gTag: string;
      privacyText: string;
      jwPlayerID: string;
      s3HostUrl: string;
      api_url: string;
      planHeader: string;
      liniusUrl: string;
      bitMovinKey: string;
      analysticBitmovinKey: string;
      ad_url?: string;
      xApiKey: string;
    };
  } = ConfigData;

  const websiteName: any = process.env.REACT_APP_ENV;

  return data?.[websiteName];
};
export default useConfig;
