import React, { useEffect, useRef } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useConfig from "../../../../hooks/useConfig";
import axios from "../../../../utils/axios";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
// @ts-ignore
import videojs from "video.js";
import "video.js/dist/video-js.css";

import lvp from "@liniusaustpty/linius-video-player";
import "@liniusaustpty/linius-video-player/dist/lvp.css";
const VideoPlayerForChannel = ({
  setCurrentTime,
  playerRef,
  setActiveClip,
  pageData,
  videoRef,
  page,
  setPage,
  currentVideoData,
  setCurrentVideoData,
  videoList,
  assetId,
  offset,
  duration,
  accessToken,
  xApiKey,
}: any) => {
  const configData = useConfig();


  useEffect(() => {
    const videoJsOptions = {
      controls: true,
      autoplay: true,
      fluid: true,

      poster: configData.logoUrl,
    };

    if (!playerRef.current) {
      // @ts-ignore
      playerRef.current = lvp.player(videoRef.current, videoJsOptions, true);
    }
    // @ts-ignore

    // videojs.Vhs.xhr.beforeRequest = (options: any) => {
    //   if (options.uri?.includes("/assembly/ldash")) {
    //     return {
    //       ...options,
    //       headers: {
    //         "Content-Type": "application/x-mpegURL",
    //         Authorization: `Bearer ${accessToken}`,
    //         "x-api-key": xApiKey,
    //       },
    //     };
    //   }
    //   return options;
    // };
    var executed = false;
    playerRef.current.on("ended", function () {
      if (!executed) {
        const findIndex = videoList.findIndex(
          (el: any) => el.id == currentVideoData.id
        );
        if (findIndex == videoList.length - 1) {
          return;
        }
        if (!pageData?.last) {
          setPage(page + 1);
        } else {
          setPage(1);
        }

        //  playerRef.current.play();
        executed = true;
      }
    });
    const findCurrentClipTime = (el: any, index: any) => {
      let sum = 0;
      for (var i = 0; i < index; i++) {
        sum += el?.duration;
      }
      return sum;
    };
    playerRef.current.on("timeupdate", function () {
      var hasPlayedTime = playerRef.current.currentTime();
      setCurrentTime(hasPlayedTime);

      const findIndex = videoList.findIndex(
        (el: any, index: any) =>
          hasPlayedTime >= findCurrentClipTime(el, index) &&
          hasPlayedTime < findCurrentClipTime(el, index) + el?.duration
      );

      if (findIndex != -1) {
        setActiveClip(videoList[findIndex]?.id);
      }
    });
    playerRef.current.src({
      src: `${configData.liniusUrl}/assembly/ldash?id=${videoList
        ?.map((el: any) => el?.assetId)
        .join(",")}&o=${videoList
        ?.map((el: any) => el?.offset)
        .join(",")}&d=${videoList?.map((el: any) => el?.duration).join(",")}`,
      type: "linius/ldash",
    });
    // playerRef.current.src({
    //   src: `${configData.liniusUrl}/assembly/ldash?id=1690197,${videoList
    //     ?.map((el: any) => el?.assetId)
    //     .join(",1690197,")},1690197&o=0,${videoList
    //     ?.map((el: any) => el?.offset)
    //     .join(",0,")},0&d=-1,${videoList
    //     ?.map((el: any) => el?.duration)
    //     .join(",-1,")},-1`,
    //   type: "linius/ldash",
    // });
  }, [assetId, offset, duration, accessToken, xApiKey]);
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);
  return (
    <div data-vjs-player>
      <video
        controls
        style={{ height: "490px" }}
        width="100%"
        ref={videoRef}
        className="video-js vjs-default-skin vjs-big-play-centered"
      />
    </div>
  );
};

export default VideoPlayerForChannel;
