import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useFormik } from "formik";
import draftToHtmlPuri from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import HttpService from "../../../../hooks/Https-services";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import useConfig from "../../../../hooks/useConfig";
function TinyEditor() {
  const [docStatus, setDocStatus] = useState(0);
  const configData = useConfig();
  const { id }: any = useParams();
  const navigate = useNavigate();
  const editorRef: any = useRef(null);
  const [isDocButtonLoading, setIsDocButtonLoading] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const updateEdit = () => {
    const blocksFromHTML = htmlToDraft("");

    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditorState(EditorState.createWithContent(state));

    formik.setFieldValue("document", formik.values.document);
    setDocStatus(1);
  };
  const validate = (values: any) => {
    const errors: any = {};

    if (!values.name) {
      errors["name"] = "This field is Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: { name: "", document: "" },
    validateOnChange: false,
    validate,
    onSubmit: async () => {
      formik.setFieldValue("document", editorRef.current.getContent());
      try {
        const data = {
          notification_content: editorRef.current.getContent(),
          notification_message: formik.values.name,
        };
        if (id == 0) {
          const res = await HttpService.post(
            APIRoutes.CreateNotification,

            data
          );
          navigate(PageRoutes.Admin);
        } else {
          const res = await HttpService.post(
            APIRoutes.EditNotification.replace(":id", id),
            data
          );
          // setDocStatus(0);
          navigate(PageRoutes.Admin);
        }
      } catch (error) {}
    },
  });
  const getAnnoucementByID = async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetListOfNotificationByID.replace(":id", id)
      );
      updateEdit();
      formik.setFieldValue("document", res.data.data?.NotificationContent);
      formik.setFieldValue("name", res.data?.data?.NotificationMessage);
    } catch (error) {}
  };
  useEffect(() => {
    if (id != 0) {
      getAnnoucementByID();
    } else {
      updateEdit();
    }
  }, []);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ width: 1, p: 4 }}>
        <button
          onClick={() => navigate(PageRoutes.Admin)}
          style={{
            cursor: "pointer",
            width: "100px",
            padding: "8px",
            margin: "10px 0px",
            fontSize: "14px",
          }}
          className={configData.buttonCommonClass}
        >
          Go Back
        </button>
        <Box sx={{ width: 1 }}>
          <Grid container rowSpacing={{ sm: 2, md: 3 }} columnSpacing={2}>
            <Grid item xs={6} md={8} lg={9}>
              <FormControl
                variant="outlined"
                sx={{ maxWidth: "420px", width: 1, mb: 1 }}
              >
                {docStatus == 1 ? (
                  <TextField
                    placeholder="Message"
                    size="small"
                    id="name"
                    fullWidth
                    name="name"
                    value={formik.values.name || ""}
                    defaultValue={formik.values.name}
                    error={formik.errors.name ? true : false}
                    helperText={
                      formik.errors.name ? (formik.errors.name as string) : ""
                    }
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.name = "";
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "template name",
                    }}
                    sx={{
                      input: {
                        bgcolor: "common.white",
                      },
                    }}
                  />
                ) : (
                  <Typography
                    sx={{ pl: 1.8, fontWeight: 600 }}
                    variant="subtitle2"
                  >
                    {formik.values.name}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box
                className="editor"
                sx={{ minHeight: 200, width: 1, bgcolor: "common.white" }}
              >
                {docStatus == 1 ? (
                  <Box sx={{ width: 1 }}>
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_KEY}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={formik.values.document}
                      disabled={docStatus != 1}
                      // onMouseUp={handlerFunction}
                      init={{
                        branding: false,
                        min_height: 500,
                        toolbar_mode: "wrap",
                        statusbar: false,
                        height: 500,
                        width: "100%",

                        plugins:
                          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                        toolbar:
                          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table mergetags | align lineheight | checklist numlist bullist indent outdent | charmap",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </Box>
                ) : (
                  <Box>
                    <span style={{ display: "none" }}>{docStatus}</span>
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_KEY}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={formik.values.document}
                      toolbar={false}
                      disabled={docStatus != 1}
                      init={{
                        branding: false,
                        min_height: 500,
                        toolbar_mode: "wrap",
                        statusbar: false,
                        height: 500,

                        width: "100%",
                        menubar: false,

                        plugins:
                          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                        toolbar:
                          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              py: 3,
            }}
          >
            {docStatus == 1 && (
              <>
                <LoadingButton
                  loading={isDocButtonLoading}
                  variant="contained"
                  sx={{ minWidth: 150 }}
                  type="submit"
                >
                  {" "}
                  Save{" "}
                </LoadingButton>
              </>
            )}
            {/* {docStatus == 0 && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    updateEdit();
                  }}
                  sx={{ minWidth: 120, mr: 2 }}
                >
                  Edit
                </Button>
              </>
            )} */}
          </Box>
        </Box>
      </Box>
    </form>
  );
}

export default TinyEditor;
