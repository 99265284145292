import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";

import FormHelpText from "rsuite/esm/FormHelpText";

import useConfig from "../../hooks/useConfig";
import HttpService from "../../hooks/Https-services";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingContext from "../../Common/Contex/LoadingContex";
import dayjs from "dayjs";
const GiftPage = () => {
  const configData = useConfig();
  const navigate = useNavigate();
  const [isLoadingData, setIsLoading] = useState(false);
  const validate = (values: any) => {
    let errors: any = {};
    if (!values?.name) {
      errors["name"] = "This field is Required";
    }
    if (!values.email) {
      errors["email"] = "This field is Required";
    }
    if (!values.date && !values.date) {
      errors["date"] = "This field is Required";
    }

    if (!values.message) {
      errors["message"] = "This field is Required";
    }

    if (!values.amount) {
      errors["amount"] = "This field is Required";
    } else if (values.amount < 20 || values.amount > 250) {
      errors["amount"] =
        "Please enter your gift amount ($20 minimum, $250 maximum)";
    }
    return errors;
  };
  const formik: any = useFormik({
    initialValues: {
      name: "",
      email: "",
      amount: "",
      send: false,
      date: "",
      message: "",
    },
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const data = values;
        const res = await HttpService.post(APIRoutes.CreateGiftCard, data);
        if (res) {
          const encodedStripeLink = encodeURIComponent(
            res.data.data.clientSecret
          );
          const encodedPaymentId = encodeURIComponent(res.data.data.plan_id);
          const encodedSubscriptionId = encodeURIComponent(
            res.data.data.paymentId
          );
          navigate(
            PageRoutes.paymentPage
              .replace(":clientSecret", encodedStripeLink)
              .replace(":plan_id", encodedPaymentId)
              .replace(":paymentId", encodedSubscriptionId)
          );
        }
        setIsLoading(false);
      } catch (error: any) {
        toast.error(error.response.data.message);
        setIsLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ mt: 5, mx: "auto", maxWidth: "1200px", width: 1, p: 3 }}>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box>
                <img width={"100%"} src={configData.giftPageLogo} />
              </Box>
              <Box>
                <Typography variant="body1">
                  Gift cards will be delivered via email on the date of your
                  choosing and can be applied against new or existing accounts.
                  The gift balance will be applied to the account and will be
                  used to for {configData.personalChannel} subscriptions. Gift
                  cards have no cash value and are non-refundable.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: 8 }}>
              <Grid container sx={{ width: 1 }} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter the recipient's name"
                    id="name"
                    name="name"
                    type={"text"}
                    label={"Enter the recipient's name"}
                    error={formik.errors.name ? true : false}
                    helperText={formik?.errors?.name}
                    value={formik.values.name}
                    variant="outlined"
                    inputProps={{
                      "aria-label": "Name",
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      formik.errors.name = "";
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter your gift amount ($20 minimum,, $250 maximum)"
                    label={
                      "Enter your gift amount ($20 minimum,, $250 maximum)"
                    }
                    id="amount"
                    type={"number"}
                    name="amount"
                    error={formik.errors.amount ? true : false}
                    helperText={formik?.errors?.amount}
                    value={formik.values.amount}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      formik.errors.amount = "";
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter the recipient's email"
                    label="Enter the recipient's email"
                    id="email"
                    name="email"
                    error={formik.errors.email ? true : false}
                    helperText={formik?.errors?.email}
                    value={formik.values.email}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      formik.errors.email = "";
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="date"
                      disablePast
                      disabled={formik.values.send}
                      sx={{ width: 1 }}
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          error: formik.errors.date ? true : false,
                          helperText: formik?.errors?.date,
                        },
                      }}
                      label="On which date should we deliver the gift?"
                      value={formik.values.date}
                      onChange={(newValue: any) => {
                        formik.setFieldValue("date", newValue);
                        formik.errors.date = "";
                      }}
                    />
                  </LocalizationProvider>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="send"
                        checked={formik.values.send}
                        onChange={(e: any) => {
                          formik.setFieldValue("date", dayjs(new Date()));
                          formik.setFieldValue("send", e.target.checked);
                          formik.errors.date = "";
                        }}
                        defaultChecked
                      />
                    }
                    label="Send Gift Now"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    placeholder="Send the recipient a short message (500 char max)"
                    label="Send the recipient a short message (500 char max)"
                    id="message"
                    name="message"
                    error={formik.errors.message ? true : false}
                    helperText={formik?.errors?.message}
                    value={formik.values.message}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      formik.errors.message = "";
                    }}
                  />
                </Grid>
              </Grid>
              <Box>
                <Box sx={{ maxWidth: "200px" }}>
                  <button
                    type="submit"
                    disabled={isLoadingData}
                    //   onClick={() => handelDeleteSlider(row?.ID)}
                    style={{
                      margin: "20px 0px",
                      cursor: "pointer",
                    }}
                    className={configData.buttonCommonClass}
                  >
                    Checkout
                  </button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </form>
  );
};
export default GiftPage;
