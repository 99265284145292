import {
  ChatRoom,
  ChatMessage,
  DeleteMessageRequest,
  DisconnectUserRequest,
  SendMessageRequest,
} from "amazon-ivs-chat-messaging";
import { v4 as uuid } from "uuid";
import { createRef, useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../utils/axios";
import { APIRoutes } from "../../../utils/constants";
import { Avatar, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import React from "react";
interface Props {
  messages: ChatMessage[];
  onDelete(id: string): Promise<void>;
}
const ChatComponent = ({ chatRoom }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [showSignIn, setShowSignIn] = useState(true);
  const [username, setUsername] = useState("");
  const [moderator, setModerator] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<any>([]);

  const [showRaiseHandPopup, setShowRaiseHandPopup] = useState(false);
  const [usernameRaisedHand, setUsernameRaisedHand] = useState(null);
  const [handRaised, setHandRaised] = useState(false);
  const previousRaiseHandUsername = useRef(null);

  const chatRef: any = createRef();
  const messagesEndRef: any = createRef();

  const renderConnect = () => {
    const status = {
      type: "SUCCESS",
      timestamp: `${Date.now()}`,
      username: "",
      userId: "",
      avatar: "",
      message: `Connected to the chat room.`,
    };
    setMessages((prevState: any) => {
      return [...prevState, status];
    });
  };
  const handleMessage = (data: any) => {
    const username = data.sender.attributes.username;
    const userId = data.sender.userId;
    const avatar = data.sender.attributes.avatar;
    const message = data.content;
    const messageId = data.id;
    const timestamp = data.sendTime;

    const newMessage = {
      type: "MESSAGE",
      timestamp,
      username,
      userId,
      avatar,
      message,
      messageId,
    };

    setMessages((prevState: any) => {
      return [...prevState, newMessage];
    });
  };

  const renderDisconnect = (reason: any) => {
    const error = {
      type: "ERROR",
      timestamp: `${Date.now()}`,
      username: "",
      userId: "",
      avatar: "",
      message: `Connection closed. Reason: ${reason}`,
    };
    setMessages((prevState: any) => {
      return [...prevState, error];
    });
  };
  const handleEvent = (event: any) => {
    const eventName = event.eventName;
    switch (eventName) {
      case "aws:DELETE_MESSAGE":
        // Ignore system delete message events, as they are handled
        // by the messageDelete listener on the room.
        break;
      case "app:DELETE_BY_USER":
        const userIdToDelete = event.attributes.userId;
        setMessages((prevState: any) => {
          // Remove message that matches the MessageID to delete
          const newState = prevState.filter(
            (item: any) => item.userId !== userIdToDelete
          );
          return newState;
        });
        break;
      default:
        console.info("Unhandled event received:", event);
    }
  };
  useEffect(() => {
    // If chat room listeners are not available, do not continue

    if (!chatRoom.addListener) {
      return;
    }

    // Hide the sign in modal

    const unsubscribeOnConnected = chatRoom.addListener("connect", () => {
      // Connected to the chat room.
      renderConnect();
    });

    const unsubscribeOnDisconnected = chatRoom.addListener(
      "disconnect",
      (reason: any) => {
        // Disconnected from the chat room.
      }
    );

    const unsubscribeOnUserDisconnect = chatRoom.addListener(
      "userDisconnect",
      (disconnectUserEvent: any) => {
        /* Example event payload:
         * {
         *   id: "AYk6xKitV4On",
         *   userId": "R1BLTDN84zEO",
         *   reason": "Spam",
         *   sendTime": new Date("2022-10-11T12:56:41.113Z"),
         *   requestId": "b379050a-2324-497b-9604-575cb5a9c5cd",
         *   attributes": { UserId: "R1BLTDN84zEO", Reason: "Spam" }
         * }
         */
        renderDisconnect(disconnectUserEvent.reason);
      }
    );

    const unsubscribeOnConnecting = chatRoom.addListener("connecting", () => {
      // Connecting to the chat room.
    });

    const unsubscribeOnMessageReceived = chatRoom.addListener(
      "message",
      (message: any) => {
        // Received a message

        const messageType = message.attributes?.message_type || "MESSAGE";
        switch (messageType) {
          default:
            handleMessage(message);
            break;
        }
      }
    );

    const unsubscribeOnEventReceived = chatRoom.addListener(
      "event",
      (event: any) => {
        // Received an event
        handleEvent(event);
      }
    );

    const unsubscribeOnMessageDeleted = chatRoom.addListener(
      "messageDelete",
      (deleteEvent: any) => {
        // Received message delete event
        const messageIdToDelete = deleteEvent.messageId;
        setMessages((prevState: any) => {
          // Remove message that matches the MessageID to delete
          const newState = prevState.filter(
            (item: any) => item.messageId !== messageIdToDelete
          );
          return newState;
        });
      }
    );

    return () => {
      unsubscribeOnConnected();
      unsubscribeOnDisconnected();
      unsubscribeOnUserDisconnect();
      unsubscribeOnConnecting();
      unsubscribeOnMessageReceived();
      unsubscribeOnEventReceived();
      unsubscribeOnMessageDeleted();
    };
  }, [chatRoom]);

  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };
    scrollToBottom();
  }, []);
  const isChatConnected = () => {
    const chatState = chatRoom.state;
    return chatState === "connected";
  };
  const renderErrorMessage = (errorMessage: any) => {
    return (
      <div className="error-line" key={errorMessage.timestamp}>
        <p>{errorMessage.message}</p>
      </div>
    );
  };

  const renderSuccessMessage = (successMessage: any) => {
    return (
      <div className="success-line" key={successMessage.timestamp}>
        <p>{successMessage.message}</p>
      </div>
    );
  };
  const renderMessage = (message: any) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: id == message?.userId ? "flex-end" : "flex-start",
        }}
      >
        <Box
          sx={{
            bgcolor: id == message?.userId ? "#ebe9ff" : "#e7e7e7",
            p: 0.5,
            width: 0.8,
            textAlign: "left",
            borderRadius: "4px",
          }}
          className="chat-line-wrapper"
          key={message.id}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",

              gap: 1,
            }}
            className="chat-line"
          >
            <Avatar
              className="chat-line-img"
              src={message.avatar}
              sx={{ width: "30px", height: "30px" }}
              alt={`Avatar for ${message.username}`}
            >
              {message.username.split(" ")[0].charAt(0)}
              {message.username.split(" ")[1].charAt(0)}
            </Avatar>
            <Box sx={{ textAlign: "start" }}>
              <Typography
                sx={{
                  fontStyle: "normal",
                  wordBreak: "break-all",
                  textTransform: "none",
                }}
                variant="body2"
                className="username"
              >
                {message.message}
              </Typography>
              <Typography variant="caption" className="username">
                {message.username}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderMessages = () => {
    return messages.map((message: any) => {
      switch (message.type) {
        case "ERROR":
          const errorMessage = renderErrorMessage(message);
          return errorMessage;
        case "SUCCESS":
          const successMessage = renderSuccessMessage(message);
          return successMessage;

        case "MESSAGE":
          const textMessage = renderMessage(message);
          return textMessage;
        default:
          console.info("Received unsupported message:", message);
          return <></>;
      }
    });
  };
  const handleChange = (e: any) => {
    setMessage(e.target.value);
  };
  const sendMessage = async (message: any) => {
    const content = `${message.replace(/\\/g, "\\\\").replace(/"/g, '\\"')}`;
    const request = new SendMessageRequest(content);
    try {
      const res = await chatRoom.sendMessage(request);
    } catch (error) {}
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (message) {
        sendMessage(message);
        setMessage("");
      }
    }
  };

  return (
    <Box
      sx={{
        p: 1,

        display: "flex",
        height: "100%",
        bgcolor: "lightgray",
        mb: 2,
      }}
      className="col-wrapper"
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,

          flexDirection: "column",
          justifyContent: "space-between",
        }}
        className="chat-wrapper"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,

            flexGrow: 1,
            overflowY: "auto",
          }}
          className="messages"
        >
          {renderMessages()}
          <div ref={messagesEndRef} />
        </Box>
        <Box
          sx={{ position: "sticky", bottom: 0, bgcolor: "white", p: 1 }}
          className="composer fl fl-j-center"
        >
          <TextField
            ref={chatRef}
            fullWidth
            size="small"
            className={`rounded mg-r-1 ${!username ? "hidden" : ""}`}
            type="text"
            placeholder={
              isChatConnected() ? "Say something" : "Waiting to connect..."
            }
            sx={{
              input: {
                fontSize: "14px",
                fontWeight: 600,
              },
            }}
            value={message}
            disabled={!isChatConnected()}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default ChatComponent;
