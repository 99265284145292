import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import useCommonDetails from "../../../../Common/Hooks/useCommonDetails";
// @ts-ignore
import WT from "@sscale/wtsdk";

import useConfig from "../../../../hooks/useConfig";
import { WatchContex } from "../../watch";
import { toast } from "react-toastify";
import { APIRoutes } from "../../../../utils/constants";

import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import axios from "../../../../utils/axios";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  createGroup,
  initializeSyncSdk,
} from "../../../../service/SynchronizeService";
import { handleSubmit } from "../../../../service/PubSub";
import roomDetails from "../../../../store/reducers/roomReducer";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import LogoutIcon from "@mui/icons-material/Logout";
import HttpService from "../../../../hooks/Https-services";

const Login = ({ videoUrl }: any) => {
  const userDetails = useCommonDetails();
  const [tokenDetails, setTokenDetails] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const configData = useConfig();
  const [errorMessage, updateErrorMessage] = useState("");
  const [error, setError] = useState("");
  const { participants, setParticipants } = useContext(WatchContex);
  const [email, setEmail] = useState("");
  const [name, setName] = useState(
    `${userDetails.FirstName} ${userDetails.LastName}`
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { gameId } = useParams();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
    setError("");
  };
  const inviteFriend = async (email: any) => {
    try {
      const res = await HttpService.post(APIRoutes.InviteSend, {
        email: email,
      });
      if (res) {
        toast.success("Invite send successfull");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
  const handleSubmitEmail = async () => {
    if (participants.length >= 5) {
      toast.error("You reached maximum numbers of participants");
      return;
    }
    setIsSkeleton(true);
    try {
      const emailTest = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );

      if (!emailTest.test(email)) {
        setError("Please enter valid email");
        setIsSkeleton(false);
        return;
      }
      const meetingId = searchParams.get("meeting_id");
      const res = await HttpService.post(APIRoutes.SendMeetingInvite, {
        email: email,
        link: `${window.location.hostname}${window.location.pathname}?meeting_id=${meetingId}`,
      });
      setEmail("");
      toast.success("Invite send successfull");
      setIsSkeleton(false);
    } catch (error: any) {
      setIsSkeleton(false);
      if (error?.response?.data?.message == "User not found") {
        toast.info(
          <Button
            onClick={() => inviteFriend(email)}
            sx={{ textTransform: "none" }}
          >
            {email} is not a user on {configData.subtitle}. Would you like to
            invite them?
          </Button>,
          { autoClose: 5000 }
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const [formData, setFormData] = useState({
    token: "",
    displayName: userDetails?.FirstName + " " + userDetails?.LastName,
    isViewerMode: false,
  });

  const handleFormInputs = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const dispatch = useDispatch();
  const handleViewerMode = (e: any) => {
    e.persist();
    setFormData({ ...formData, isViewerMode: e.target.checked });
  };
  const startConnecting = async (e: any) => {
    if (searchParams.get("meeting_id")) {
      return;
    }
    setIsSkeleton(true);
    try {
      const partyDetais = await HttpService.post(APIRoutes.StartParty, {
        gameId: gameId,
      });
      e.preventDefault();
      setTokenDetails(partyDetais?.data?.data);
      setSearchParams((prev: any) => [
        ...prev.entries(),
        ["meeting_id", partyDetais?.data?.data.MeetingID],
      ]);
      const token = partyDetais?.data?.data?.WTToken;

      const connect = false ? WT.Session.connectAsAViewer : WT.Session.connect;

      connect(token, formData.displayName, {
        audio: true,
        video: true,
      });
      const room = {
        token: partyDetais?.data?.data?.SyncToken,
        clientName: formData.displayName,
        video: videoUrl,
      };
      dispatch(roomDetails.actions.removeRoomDetails());
      dispatch(roomDetails.actions.setRoomDetails(room));
      handleSubmit(partyDetais?.data?.data?.SyncToken, formData.displayName);
      setIsSkeleton(false);
    } catch (error: any) {
      setIsSkeleton(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const location = useLocation();
  const handleLeave = () => {
    searchParams.delete("meeting_id");
    setSearchParams(searchParams);
    window.location.reload();
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: "lightgray",
          height: "100%",
          color: "#000",
          px: 1,
        }}
      >
        <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: 600 }}>
          This feature is currently in Beta and will likely be part of the
          Insider Plan when complete.
        </Typography>

        <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 1 }}>
          {participants.map((el: any) => (
            <Box
              sx={{
                display: "flex",
                width: 1,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2">{el.participantName}</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                {el.local && (
                  <Box sx={{ cursor: "pointer" }} onClick={handleLeave}>
                    <LogoutIcon />
                  </Box>
                )}

                {el.settings.mutedAudio ? <MicIcon /> : <MicOffIcon />}
                {el.settings.mutedVideo ? (
                  <VideocamIcon />
                ) : (
                  <VideocamOffIcon />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{ position: "sticky", bottom: 0 }}>
        <div className="login-wrapper">
          <div className="login-container">
            <Box sx={{ width: 1, bgcolor: configData.tertiary }}>
              {open && (
                <TextField
                  error={error ? true : false}
                  size="small"
                  sx={{ my: 1 }}
                  placeholder={"Enter email"}
                  fullWidth
                  helperText={error}
                  value={email}
                  onChange={handleChangeEmail}
                />
              )}
              {participants?.length == 0 ? (
                <button
                  className={configData.buttonCommonClass}
                  disabled={isSkeleton}
                  onClick={startConnecting}
                >
                  Start Watch Party
                </button>
              ) : (
                <button
                  className={configData.buttonCommonClass}
                  disabled={isSkeleton}
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (!open) {
                      setOpen(true);
                    } else {
                      handleSubmitEmail();
                    }
                  }}
                >
                  Invite Your Friend
                </button>
              )}
            </Box>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Login;
