import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const theme = (colours) =>
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 567,
        md: 768,
        lg: 992,
        xl: 1440,
      },
    },
    palette: {
      common: {
        black: "#000000",
        white: "#ffffff",
        grey: "#D9D9D9",
        darkBlue: "#003260",
        lightBlue: "#D9EDFF",
        pageBackground: "#FBFBFF",
        green: "#479706",
        orange: "#FBBF24",
        borderColor: colours.primary,
      },
      primary: {
        light: colours.primary,
        main: colours.primary,
        dark: "#0f40ec",
        contrastText: "#ffffff",
      },
      secondary: {
        light: colours.secondary,
        main: colours.secondary,
        dark: "#0053A0",
        contrastText: "#ffffff",
      },
      text: {
        primary: "#000000",
      },
      error: {
        main: red.A700,
      },
    },
    typography: {
      fontFamily: "SF UI Text",
      color: "white",
      lineHeight: 1.3,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightSemibold: 600,
      fontWeightBold: 700,
      h1: {
        fontFamily: "SF UI Text",
        fontWeight: 600,
        fontSize: "32px",
        color: "#000000",
        lineHeight: "48px",
        letterSpacing: "normal",
        "@media (max-width: 768px)": {
          fontSize: "22px",
          lineHeight: "36px",
        },
      },
      h2: {
        fontFamily: "SF UI Text",
        fontWeight: 500,
        color: "#000000",
        fontSize: "24px",
        lineHeight: "36px",
        letterSpacing: "normal",
      },
      h3: {
        fontFamily: "SF UI Text",
        fontWeight: 500,
        fontSize: "22px",
        lineHeight: "32px",
        letterSpacing: "normal",
        color: "#000000",
        "@media (max-width: 768px)": {
          fontSize: "18px",
          lineHeight: "28px",
        },
      },
      subtitle1: {
        fontFamily: "SF UI Text",
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "28px",
        letterSpacing: "normal",
        color: "#000000",
        "@media (max-width: 768px)": {
          fontSize: "18px",
          lineHeight: "28px",
        },
      },
      subtitle2: {
        fontFamily: "SF UI Text",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "22px",
        letterSpacing: "normal",
        color: "#000000",
        "@media (max-width: 768px)": {
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
      body1: {
        fontFamily: "SF UI Text",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
        color: "#000000",
      },
      body2: {
        fontFamily: "SF UI Text",
        fontWeight: 400,
        fontSize: "14px",
        color: "#000000",
      },
      button: {
        fontFamily: "SF UI Text",
        fontWeight: 400,
        color: "#000000",
      },
      caption: {
        fontFamily: "SF UI Text",
        fontWeight: 400,
        fontSize: "12px",
        color: "#000000",
      },
      overline: {
        fontFamily: "SF UI Text",
        fontWeight: 400,
        color: "#000000",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: colours.primary,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
          },
        },
      },
    },
  });

export default theme;
