import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import useConfig from "../../../hooks/useConfig";
import Typography from "@mui/material/Typography";
import "./index.css";
import { useMediaQuery } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../utils/axios";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { toast } from "react-toastify";
import { UserContext } from "..";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import HttpService from "../../../hooks/Https-services";

function CreateChannel(props: any) {
  const { handleNext } = props;

  // const configData = useConfig();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const { channel, channelData, setChannelName, loading, setLoading }: any =
    React.useContext(UserContext);

  const handleChange = (e: any) => {
    setChannelName(e.target.value);
  };
  const navigate = useNavigate();
  const { channel_id }: any = useParams();
  const createChannel = async () => {
    const configure = {
      Name: channelData,
      Teams: [],
      Players: [],
      Events: [],
      All: [],
    };
    const updateConfigure = {
      Name: channelData,
      Teams: channel?.Configuration?.Teams,
      Players: channel?.Configuration?.Players,
      Events: channel?.Configuration?.Events,
      All: channel?.Configuration?.All,
    };
    setLoading(true);
    try {
      if (channel) {
        const res = await HttpService.post(
          APIRoutes.UpdateChannel.replace(":id", channel_id),
          {
            configure: updateConfigure,
          }
        );
        navigate(
          PageRoutes.MyNAHLTV.replace(":id", "2").replace(
            ":channel_id",
            channel_id
          )
        );
        setLoading(false);
      } else {
        const res = await HttpService.post(APIRoutes.CreateChannel, {
          configure: configure,
        });
        navigate(
          PageRoutes.MyNAHLTV.replace(":id", "2").replace(
            ":channel_id",
            res?.data?.data?.ID
          )
        );

        setLoading(false);
      }

      handleNext();
    } catch (error: any) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };
  const configData = useConfig();
  return (
    <Container component="main">
      <CssBaseline />
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="title">
                Build your channel
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  onChange={handleChange}
                  defaultValue={channel?.Configuration?.Name}
                  value={channelData}
                  fullWidth
                  id="channelName"
                  label="Your channel name"
                  name="channelName"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="Would you like highlights and video emailed to you when new content becomes available?"
                />
              </Grid>
            </Grid>
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              disabled={!channelData ? true : false}
              variant="contained"
              onClick={createChannel}
              sx={{
                my: { xs: 1, md: 2 },
                textAlign: "center",
                background: configData.secondary,
                "&:hover": { background: configData.secondary },
              }}
            >
              Next <KeyboardArrowRightIcon />
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </Container>
  );
}

export default CreateChannel;
