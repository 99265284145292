import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import Passes from ".";
import useConfig from "../../hooks/useConfig";

const PassesMain = ({ isHomePage }: any) => {
  const configData = useConfig();
  const stripeKey: any = configData?.stripePublishableKey;
  const stripe = loadStripe(stripeKey);

  return (
    <Elements stripe={stripe}>
      <Passes isHomePage={isHomePage} />
    </Elements>
  );
};
export default PassesMain;
