import { Box } from "@mui/system";
import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import AlertDesign from "./AlertDesign/TinyEditor";
import ListOfAlerts from "./AlertDesign/ListOfAlerts";
import ListOfSlider from "./SliderSettings/ListOfSlider";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PageRoutes, { UserRoles } from "../../utils/constants";
import UserList from "./UsersList";
import PlayerAlertList from "./PlayerAlertList";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Admin = () => {
  const location = useLocation();
  const userDetails = useCommonDetails();
  const [value, setValue] = React.useState<string>(location.pathname);

  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
    setValue(newValue);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN].includes(
            userDetails?.UserRoleID
          ) && <Tab label="Alert" value={PageRoutes.Admin} />}{" "}
          {[
            UserRoles.HTP_ADMIN,
            UserRoles.LEAGUE_ADMIN,
            UserRoles.CUSTOMER_ADMIN,
          ].includes(userDetails?.UserRoleID) && (
            <Tab label="Sliders" value={PageRoutes.SliderSetting} />
          )}
          {[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN].includes(
            userDetails?.UserRoleID
          ) && <Tab label="Users" value={PageRoutes.Users} />}
          {[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN].includes(
            userDetails?.UserRoleID
          ) && <Tab label="Player Alerts" value={PageRoutes.PlayerAlerts} />}
        </Tabs>
        <TabPanel value={value} index={PageRoutes.Admin}>
          <ListOfAlerts />
        </TabPanel>
        <TabPanel value={value} index={PageRoutes.SliderSetting}>
          <ListOfSlider />
        </TabPanel>
        <TabPanel value={value} index={PageRoutes.Users}>
          <UserList />
        </TabPanel>
        <TabPanel value={value} index={PageRoutes.PlayerAlerts}>
          <PlayerAlertList />
        </TabPanel>
      </Box>
    </Box>
  );
};
export default Admin;
