import React, { useCallback, useContext, useState } from "react";
import { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";

import useConfig from "../../../hooks/useConfig";

import { APIRoutes } from "../../../utils/constants";
import "./videoplayer.css";
//@ts-ignore
import Marquee from "react-marquee-line";
import { PlayerModule as AnalyticsModule } from "bitmovin-analytics";
import { PlayerEvent, Player } from "bitmovin-player";
import {
  groupSeek,
  pauseGroup,
  playGroup,
  setBitmovinToSdk,
} from "../../../service/SynchronizeService";
import "bitmovin-player/bitmovinplayer-ui.css";
import { isiOS } from "../../../helper/device";
import { off, on } from "../../../service/PubSub";
import { useSelector, shallowEqual } from "react-redux";
import useCommonDetails from "../../Hooks/useCommonDetails";
import HttpService from "../../../hooks/Https-services";
//@ts-ignore
import { UIFactory } from "bitmovin-player/bitmovinplayer-ui";
import LoadingContext from "../../Contex/LoadingContex";
import useCheckHasPermission from "../../../hooks/useCheckHasPermission";
import { Box } from "@mui/material";
import { WatchContex } from "../../../Pages/Watch/watch";

declare global {
  interface Window {
    bitmovin: any;
  }
}

interface bitMovingPlayerProps {
  videoUrl: string;
  gameDetails: any;
  videoAway: boolean;
  setRunning: any;
  playerRef: any;
  autoPlay: boolean;
  setIsSeeking: any;
}
const BitMovingPlayer: React.FC<bitMovingPlayerProps> = ({
  videoUrl,
  playerRef,
  gameDetails,
  videoAway,
  setIsSeeking,

  autoPlay,

  setRunning,
}) => {
  const startTimeRef = useRef<number>(0);
  const totalTimeWatchedRef = useRef<number>(0);
  const isPlayingRef = useRef<boolean>(false);
  const liveWasteTimeRef = useRef<number>(0);
  const intervalRef = useRef<any>(null);
  const bitrateRef = useRef<number>(0);
  const resolutionRef = useRef<number>(0);
  const { gameId }: any = useParams();
  const userDetails = useCommonDetails();

  const hasPermisstion = useCheckHasPermission(userDetails, "stopwatch");
  const [browser, setBrowser] = useState<any>("");
  const [hidePoster, setHidePoster] = useState<any>(false);
  const userAgent = navigator.userAgent;
  const location = useLocation();
  const configData = useConfig();
  const playerId = configData.jwPlayerID;
  const { adsDetails, handleChange, isWatchAdd } = useContext(WatchContex);
  // Event handler for seek end
  const handleSeekEnd = () => {
    setIsSeeking();
  };

  const room: any = useSelector(
    ({ roomDetails }: any) => roomDetails?.roomDetails,
    shallowEqual
  );
  const {
    openColleps,
    receivedMessage,
    setReceivedMessage,
    errorStyle,
    setReceivedImage,
    receivedImage,
    CloseColleps,
    setGameId,
  }: any = useContext(LoadingContext);
  const [ipAdress, setIpAdress] = useState("");
  const getIp = async () => {
    const ip: any = await fetch("https://api.ipify.org?format=json");
    const ip2 = await ip.json();
    localStorage.setItem("ip", ip2.ip);
  };
  // useEffect(() => {
  //   if (playerRef.current && openColleps) {
  //     var uiManager = UIFactory.buildDefaultUI(playerRef.current, {});
  //     const player1 = uiManager.currentUi.playerWrapper.getPlayer();
  //     player1.on("error", console.log);
  //     player1.fireEventInUI("error", {
  //       message:
  //         "The Venue is experiencing Internet issues.  We apologize for the inconvenience.” or “Please refresh your browser to reconnect.",
  //       name: "The Venue is experiencing Internet issues.  We apologize for the inconvenience.” or “Please refresh your browser to reconnect.",
  //       code: "",
  //     });
  //     playerRef.current.unload();
  //   }
  // }, [openColleps]);
  useEffect(() => {
    // Function to initialize JW Player
    getIp();
    setGameId(gameId);
    var conf: any = {
      key: configData?.bitMovinKey,
      location: {
        ui: "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js",
        ui_css: "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css",
      },
      analytics: {
        key: configData.analysticBitmovinKey,
        title: `${gameDetails?.away_team} vs ${gameDetails?.home_team}`,
        videoId: `${gameDetails?.game_id}`,
        customUserId: userDetails.UserID,
      },
      playback: {
        autoplay: true,
        timeShift: !gameDetails?.disable_dvr,
      },
      cast: {
        enable: true,
      },
      advertising: adsDetails?.ChannelIDs.includes(
        gameDetails?.broadcast_platform_channel_id
      ) &&
        !isWatchAdd && {
          adBreaks: [
            {
              tag: {
                type: "vast",
                // More Ad Samples can be found here:
                // https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/tags
                url: `${adsDetails.MainfestURL}?cb=${adsDetails?.Cachebust}`,
              },
            },
          ],
        },
    };

    var source = {
      hls: room?.video ? room?.video : videoUrl,
      dash: undefined,
    };
    Player.addModule(AnalyticsModule);
    playerRef.current = new Player(
      document.getElementById(configData?.bitMovinKey) as any,
      conf
    );

    const src = {
      hls: undefined,
      dash: undefined,
    };
    let firstSeek = true;
    // Setup the player
    playerRef.current.load(source).then(() => {
      room?.video && setBitmovinToSdk(playerRef.current);
    });
    playerRef.current.on(PlayerEvent.Play, () => {
      startTimeRef.current = new Date().getTime();
      isPlayingRef.current = true;
      if (window.sdkSeek) {
        window.sdkSeek = false;
        return;
      }

      setRunning(true);
    });
    playerRef.current.on(PlayerEvent.Seeked, function () {
      handleSeekEnd();

      if (window.sdkSeek) {
        window.sdkSeek = false;
        return;
      }

      groupSeek();
    });

    playerRef.current.on(PlayerEvent.TimeShifted, function () {
      if (window.sdkSeek) {
        window.sdkSeek = false;
        return;
      }

      groupSeek();
    });
    const handleGroupPause = () => playerRef.current.pause();
    const handleGroupPlay = () => playerRef.current.play();
    const handleGroupMute = () => playerRef.current.mute();
    const handleGroupUnmute = () => playerRef.current.unmute();
    const handleGroupVolume = (volume: number) =>
      playerRef.current.setVolume(volume * 100);
    const handleGroupSeek = (delta: number) =>
      playerRef.current.seek(
        Math.max(playerRef.current.getCurrentTime() + delta / 1000, 0)
      );

    on("group_pause", handleGroupPause);
    on("group_play", handleGroupPlay);
    on("group_mute", handleGroupMute);
    on("group_unmute", handleGroupUnmute);
    on("group_volume", handleGroupVolume);
    on("group_seek", handleGroupSeek);

    playerRef.current.on(PlayerEvent.Paused, () => {
      if (isPlayingRef.current) {
        totalTimeWatchedRef.current +=
          new Date().getTime() - startTimeRef.current;
      }
      if (window.sdkSeek) {
        window.sdkSeek = false;
        return;
      }

      setRunning(false);

      isPlayingRef.current = false;
    });
    const dataToPost = {
      liveWasteTime: liveWasteTimeRef.current,
      bitrate: bitrateRef.current,
      resolution: resolutionRef.current,
      // Add any other data you want to send to the server
    };
    playerRef.current.on(PlayerEvent.PlaybackFinished, () => {
      totalTimeWatchedRef.current +=
        playerRef.current?.getDuration() -
        (new Date().getTime() - startTimeRef.current);
      // Log or send totalTimeWatchedRef.current to the server
      isPlayingRef.current = false;

      // clearInterval(postInterval);
    });
    const findBowser = () => {
      if (
        userAgent.indexOf("Opera") !== -1 ||
        userAgent.indexOf("OPR") !== -1
      ) {
        return "Opera";
      } else if (userAgent.indexOf("Edge") !== -1) {
        return "Edge";
      } else if (userAgent.indexOf("Chrome") !== -1) {
        return "Chrome";
      } else if (userAgent.indexOf("Safari") !== -1) {
        return "Safari";
      } else if (userAgent.indexOf("Firefox") !== -1) {
        return "Firefox";
      } else if (userAgent.indexOf("MSIE") !== -1) {
        return "Internet Explorer";
      } else {
        return "Unknown";
      }
    };

    // Event listeners for live video waste time
    intervalRef.current = setInterval(async () => {
      if (isPlayingRef.current) {
        if (liveWasteTimeRef.current >= 15000) {
          const currentPosition =
            playerRef.current.getCurrentTime("relativetime");
          const isLive = playerRef.current.isLive();

          const dataToPost = {
            liveWasteTime: liveWasteTimeRef.current.toString(),
            bitrate: bitrateRef.current.toString(),
            gameId: gameId.toString(),
            browser: findBowser(),
            currentTime: currentPosition.toString(),
            resolution: resolutionRef.current.toString(),
            Attribute: gameDetails?.is_live ? "live" : "vod",
            feed: videoAway ? "away" : "home",
            ip: localStorage.getItem("ip") || "",
            // Add any other data you want to send to the server
          };
          liveWasteTimeRef.current = 0;
          const res = await HttpService.post(
            APIRoutes.PostVideoAnalyse,
            dataToPost
          );
        } else {
          liveWasteTimeRef.current += 1000;
        }
      }
    }, 1000);

    playerRef.current.on(PlayerEvent.VideoQualityChanged, (e: any) => {
      const { targetQuality } = e;

      if (typeof targetQuality["bitrate"] === "number") {
        bitrateRef.current = targetQuality["bitrate"];
      }
      resolutionRef.current = targetQuality["height"];
    });
    handleChange();

    return () => {
      clearInterval(intervalRef.current);
      liveWasteTimeRef.current = 0;
      playerRef.current.unload();
      off("group_pause", handleGroupPause);
      off("group_play", handleGroupPlay);
      off("group_mute", handleGroupMute);
      off("group_unmute", handleGroupUnmute);
      off("group_volume", handleGroupVolume);
      off("group_seek", handleGroupSeek);
    };
  }, [playerId, videoUrl, videoAway, room.video]);
  const list = [receivedMessage];
  console.log(errorStyle);

  return (
    <Box className="player-container" id={configData?.bitMovinKey}>
      {openColleps && (
        <Box
          sx={{
            position: "absolute",
            height:
              errorStyle?.Type === "text"
                ? "30px"
                : { xs: "50px", lg: "100px" },
            ...(errorStyle?.Placement === "top"
              ? { top: 0 }
              : errorStyle?.Placement === "bottom"
              ? { bottom: 0 }
              : {
                  bottom: 0,
                  top: {
                    xs: errorStyle?.Type === "text" ? "50%" : "40%",
                  },
                }),
            mx: "auto",
            left: 0,
            right: 0,
            zIndex: 100,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "#fff",

            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: 1,
              overflow: "hidden",
              whiteSpace: "nowrap",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                paddingLeft: "100%",
                height: 1,
                width: "max-content",
                boxSizing: "border-box",
                animation: `ticker-scroll ${
                  (18 * 50) / errorStyle?.Speed
                }s linear infinite`,
              }}
            >
              {" "}
              <style>
                {`
          @keyframes ticker-scroll {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(-100%);
            }
          }
        `}
              </style>
              {errorStyle?.Type === "text" ? (
                receivedMessage
              ) : (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={receivedImage}
                  style={{ objectFit: "contain", height: "100%" }}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BitMovingPlayer;
