import {
  TextField,
  Box,
  Dialog,
  Grid,
  Button,
  FormControl,
  Select,
  MenuList,
  MenuItem,
  InputLabel,
  Typography,
  Stack,
  Autocomplete,
  Slider,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useFormik } from "formik";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import HttpService from "../../../../../hooks/Https-services";
import useConfig from "../../../../../hooks/useConfig";
import PageRoutes, { APIRoutes } from "../../../../../utils/constants";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormHelpText from "rsuite/esm/FormHelpText";
import _ from "lodash";
import "./index.css";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const CreatePlayerError = () => {
  const configData = useConfig();
  const { id }: any = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [channelOption, setChannelOption] = useState([]);
  const [hidePoster, setHidePoster] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const [intialvalue, setIntialValue] = useState({
    gameId: null,
    displayText: "",
    placement: "bottom",
    speed: 50,
    type: "text",
    images: null as any,
    Image: null,
    time: 50,
  });
  const [searchFilter, setFilter] = useState<any>(null);
  const [vodGames, setVodGames] = useState([]);
  const [liveGame, setLiveGames] = useState([]);
  const [selectedType, setSelectedType] = useState(intialvalue?.type || "text");
  const validate = (values: any) => {
    let errors: any = {};

    if (!values.gameId) {
      errors["gameId"] = "This field is Required";
    }

    return errors;
  };
  const formik: any = useFormik({
    initialValues: intialvalue,
    validate,
    enableReinitialize: true,

    validateOnChange: false,
    onSubmit: async (values: any) => {
      try {
        const formData = new FormData();
        const payload: any = {
          message: values.displayText,
          game_id: values.gameId?.game_id,
          customer_id: configData.customer,
          is_active: false,
          placement: values.placement,
          speed: values.speed,
          game_details: {
            game_id: values.gameId?.game_id,
            away_team: values.gameId?.away_team,
            home_team: values.gameId?.home_team,
            away_team_id: values.gameId?.away_team_id,
            home_team_id: values.gameId?.home_team_id,
          },
        };

        formData.append("message", values?.displayText);

        formData.append("game_id", values.gameId?.game_id);
        formData.append("customer_id", configData.customer);
        formData.append("placement", values.placement);
        formData.append("type", selectedType);
        formData.append("time", values.time);
        if (values.images) {
          formData.append("image", values.images);
        }
        formData.append(
          "game_details",
          JSON.stringify({
            game_id: values.gameId?.game_id,
            away_team: values.gameId?.away_team,
            home_team: values.gameId?.home_team,
            away_team_id: values.gameId?.away_team_id,
            home_team_id: values.gameId?.home_team_id,
          })
        );
        if (id && id != 0) {
          delete payload.is_active;
          const res = await HttpService.post(
            APIRoutes.UpdateErrorMessageByID.replace(":id", id),
            formData
          );
        } else {
          const res = await HttpService.post(
            APIRoutes.CreateErrorMessage,
            formData
          );
        }

        navigate(PageRoutes.PlayerAlerts);
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
    },
  });

  const renderOptions = useCallback(() => {
    const optionsVod = vodGames.map((option: any) => {
      const firstLetter = "On Demand";
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });
    const optionsLive = liveGame.map((option: any) => {
      const firstLetter = "Live and Upcoming";
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });
    return [...optionsVod, ...optionsLive];
  }, [vodGames, liveGame]);
  const FetchTeamLIVE = async (searchFilter: any) => {
    try {
      const params: any = {
        customer: configData.customer,
        status: "live",

        page: 1,
        search: searchFilter?.search,
        limit: 5,
      };

      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });

      const data = result.data.data;
      setLiveGames(data);
    } catch (error) {
    } finally {
      //   setIsLoading(false);
    }
  };
  const handleChange = (event: Event, newValue: number | number[]) => {
    formik.setFieldValue("speed", newValue as number[]);
  };
  const FetchTeamVOD = async (searchFilter: any) => {
    try {
      const params: any = {
        customer: configData.customer,
        status: "vod",
        page: 1,
        search: searchFilter?.search,
        limit: 5,
      };

      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });

      const data = result.data.data;
      setVodGames(data);
      //   setPage(page + 1);
    } catch (error) {
    } finally {
      //   setIsLoading(false);
    }
  };
  const checkPhotoIsInSize = (fileUpload: any) => {
    var reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(fileUpload[0]);
    reader.onload = function (e: any) {
      //Initiate the JavaScript Image object.
      var image: any = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        var height = this.height;
        var width = this.width;

        if (height == 200) {
          formik.setFieldValue("images", fileUpload[0]);
          setFileUrl(URL.createObjectURL(fileUpload[0]));
          return true;
        } else {
          toast.error("Images must be 2560px(width) x 792px(height)");
          return false;
        }
      };
    };
  };
  const handleClose = () => {
    setOpen(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchUserFavouriteTeamVod = useCallback(
    _.debounce(FetchTeamVOD, 500),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchUserFavouriteTeamLive = useCallback(
    _.debounce(FetchTeamLIVE, 500),
    []
  );
  const GetPlayerAlertByID = useCallback(async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetErrorMessagesByID.replace(":id", id)
      );
      const data = res?.data?.data;
      setSelectedType(data?.Type);
      setIntialValue({
        ...intialvalue,
        displayText: data?.Message,
        gameId: data?.GameDetails,
        speed: data?.Speed,
        placement: data?.Placement,
        type: data?.Type,
        Image: data?.Image,
        time: data?.Time,
      });
      setFileUrl(data?.Image);
    } catch (error) {}
  }, []);
  useEffect(() => {
    if (id != 0) GetPlayerAlertByID();
  }, [GetPlayerAlertByID, id]);
  useEffect(() => {
    debouncedFetchUserFavouriteTeamVod(searchFilter);

    // Cleanup function to cancel the previous debounced function
  }, [debouncedFetchUserFavouriteTeamVod, searchFilter]);
  useEffect(() => {
    debouncedFetchUserFavouriteTeamLive(searchFilter);

    // Cleanup function to cancel the previous debounced function
  }, [debouncedFetchUserFavouriteTeamLive, searchFilter]);
  const marqueeRef = useRef(null);

  useEffect(() => {
    const marqueeText: any = marqueeRef.current;
    const pauseTime = 10000; // Pause duration in milliseconds

    const handleAnimationIteration = () => {
      marqueeText.style.animationPlayState = "paused";
      setTimeout(() => {
        marqueeText.style.animationPlayState = "running";
      }, pauseTime);
    };

    marqueeText.addEventListener(
      "animationiteration",
      handleAnimationIteration
    );

    return () => {
      marqueeText.removeEventListener(
        "animationiteration",
        handleAnimationIteration
      );
    };
  }, []);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPaused(true);
      setTimeout(() => {
        setPaused(false);
      }, 10000); // Pause for 10 seconds
    }, 2000); // Duration before pause starts

    return () => clearTimeout(timeout);
  }, [paused]);
  return (
    <Box sx={{ p: 4, maxWidth: 800, mx: "auto" }}>
      <button
        onClick={() => navigate(PageRoutes.PlayerAlerts)}
        style={{
          cursor: "pointer",
          width: "100px",
          padding: "8px",
          margin: "10px 0px",
          fontSize: "14px",
        }}
        className={configData.buttonCommonClass}
      >
        Go Back
      </button>
      <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
        <Typography variant="subtitle1">
          {id == 0 ? "Create" : "Update"} Player Alert
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl>
              <RadioGroup
                row
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="text"
                  control={<Radio />}
                  label="Text"
                />
                <FormControlLabel
                  value="graphics"
                  control={<Radio />}
                  label="Graphics"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {selectedType === "text" ? (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Display Text"
                label="Display Text"
                id="displayText"
                name="displayText"
                value={formik.values.displayText}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  formik.errors.displayText = "";
                }}
              />
              {formik?.errors?.displayText && (
                <FormHelpText> {formik?.errors?.displayText}</FormHelpText>
              )}
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              <Stack>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Image
                  <VisuallyHiddenInput
                    type="file"
                    accept=".png"
                    onChange={(e) => checkPhotoIsInSize(e.target.files)}
                  />
                </Button>

                <Typography variant="caption" color={"warning"}>
                  Sliders must be PNGs and 200px(height)
                </Typography>
              </Stack>
              {fileUrl && (
                <Box sx={{ width: "200px", py: 2 }}>
                  <img onClick={handleClickOpen} src={fileUrl} width={"100%"} />
                </Box>
              )}
            </Grid>
          )}

          <Grid container item xs={12} sm={6} spacing={1.5}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  id="grouped-demo"
                  onChange={(e: any, value: any) => {
                    formik.setFieldValue("gameId", value);
                    formik.errors.gameId = "";
                  }}
                  freeSolo
                  options={renderOptions().sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) =>
                    `${option.away_team} @ ${option.home_team} `
                  }
                  value={formik.values.gameId}
                  renderInput={(params) => (
                    <TextField
                      onChange={(e: any) =>
                        setFilter({ ...searchFilter, search: e.target.value })
                      }
                      {...params}
                      label="Select Target Game"
                    />
                  )}
                />
                <FormHelpText> {formik?.errors?.gameId}</FormHelpText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} spacing={1.5}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Placement</InputLabel>
              <Select
                label={"Placement"}
                placeholder="Placement"
                id="placement"
                name="placement"
                value={formik.values.placement}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  formik.errors.placement = "";
                }}
              >
                <MenuItem value="bottom">Bottom</MenuItem>
                <MenuItem value="top">Top</MenuItem>
                <MenuItem value="center">Center</MenuItem>
              </Select>
              {/* <FormHelpText> {formik?.errors?.seasonId}</FormHelpText> */}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} spacing={1.5}>
            <FormLabel>Speed</FormLabel>
            <FormControl fullWidth>
              <Slider
                defaultValue={50}
                aria-label="Default"
                value={formik.values.speed}
                onChange={handleChange}
                valueLabelDisplay="on"
              />
              {/* <FormHelpText> {formik?.errors?.seasonId}</FormHelpText> */}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} spacing={1.5}>
            <FormLabel>Time</FormLabel>
            <FormControl fullWidth>
              <Slider
                min={10}
                defaultValue={50}
                aria-label="Default"
                value={formik.values.time}
                onChange={(e: any, newValue: any) =>
                  formik.setFieldValue("time", newValue as number[])
                }
                valueLabelDisplay="on"
              />
              {/* <FormHelpText> {formik?.errors?.seasonId}</FormHelpText> */}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} spacing={1.5}>
            <FormLabel>Output</FormLabel>
            <Box
              sx={{
                height: 200,
                border: "1px solid #dadada",
                borderRadius: 2,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  height: selectedType === "text" ? "30px" : "100px",
                  ...(formik.values?.placement === "top"
                    ? { top: 0 }
                    : formik.values?.placement === "bottom"
                    ? { bottom: 0 }
                    : { top: "50%", transform: "translateY(-50%)" }),
                  mx: "auto",
                  left: 0,
                  right: 0,
                  zIndex: 100,
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: 1,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    boxSizing: "border-box",
                    position: "relative",
                  }}
                >
                  <Box
                    ref={marqueeRef}
                    sx={{
                      display: "inline-block",
                      paddingLeft: "100%",
                      width: "max-content",
                      height: 1,
                      boxSizing: "border-box",
                      animation: `ticker-scroll ${
                        (18 * 50) / formik.values?.speed
                      }s linear infinite`,
                    }}
                  >
                    <style>
                      {`
          @keyframes ticker-scroll {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(-100%);
            }
          }
        `}
                    </style>
                    {selectedType === "text" ? (
                      formik.values?.displayText
                    ) : (
                      <img
                        src={fileUrl}
                        width="100%"
                        style={{ height: "100%", margin: "auto" }}
                        alt="Sliding"
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          maxWidth={"md"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/*  eslint-disable-next-line jsx-a11y/alt-text */}
          <img src={fileUrl} width={"100%"} />
        </Dialog>

        <Box
          sx={{
            display: "flex",
            width: 1,

            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ maxWidth: "200px" }}>
            <button
              type="submit"
              //   onClick={() => handelDeleteSlider(row?.ID)}
              style={{
                margin: "20px 0px",
                cursor: "pointer",
              }}
              className={configData.buttonCommonClass}
            >
              Submit
            </button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
export default CreatePlayerError;
